import WIDGETS from './widgets';

export default {
  name: 'chat',
	spacing: 0,
	sx: {
		height: '100%',
	},
	widgets: [
		// { content: WIDGETS.HEADER, breakpoints: { xs: 12 }, lazy_loading: 0 },
		{
			content: WIDGETS.CHAT,
			breakpoints: { xs: 12 },
			lazy_loading: 0,
			component_props: { stack_divider: true, stack_props: { sx: { height: '100%' } } },
		},
	],
};
