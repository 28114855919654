export const FETCH_QUESTIONNAIRES_STATISTICS   = 'FETCH_QUESTIONNAIRES_STATISTICS';
export const FETCH_QUESTION_GROUPS   = 'FETCH_QUESTION_GROUPS';
export const FETCH_STATISTICS_CONTACTS   = 'FETCH_STATISTICS_CONTACTS';
export const FETCH_STATISTICS_CONTACTS_INTERVAL   = 'FETCH_STATISTICS_CONTACTS_INTERVAL';
export const FETCH_STATISTICS_CONTACTS_CATEGORY   = 'FETCH_STATISTICS_CONTACTS_CATEGORY';
export const FETCH_STATISTICS_CONTACTS_CATEGORY_GROUP   = 'FETCH_STATISTICS_CONTACTS_CATEGORY_GROUP';
export const FETCH_STATISTICS_CONTACTS_TYPE   = 'FETCH_STATISTICS_CONTACTS_TYPE';
export const FETCH_STATISTICS_CONTACTS_TAG_GROUP   = 'FETCH_STATISTICS_CONTACTS_TAG_GROUP';
export const FETCH_STATISTICS_TICKETS_REFERRAL   = 'FETCH_STATISTICS_TICKETS_REFERRAL';
export const FETCH_STATISTICS_CONTACTS_GENDER   = 'FETCH_STATISTICS_CONTACTS_GENDER';
export const SET_FROM_DATE   = 'SET_FROM_DATE';
export const SET_TO_DATE   = 'SET_TO_DATE';
export const SET_CLIENT   = 'SET_CLIENT';
export const SET_INTERVAL   = 'SET_INTERVAL';
