import { isChrome } from 'react-device-detect';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import momentTimezone from 'moment-timezone';
import FORMATS from './../date_formats';

function getByTimezone(datetime, timezone, format = 'YYYY-MM-DD HH:mm:ss') {
	return momentTimezone.utc(datetime).tz(timezone).format(format);
}

function formatLocaleDate(date, locale) {
	const dateformat = new Date(date);
	const options = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	};

	if (date && dateformat instanceof Date && !isNaN(dateformat)) {
		return dateformat.toLocaleString(locale, options);
	} else {
		return date;
	}
}

function formatDateRemoveTime(date, locale) {
	const dateformat = new Date(date);
	const options = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	};

	if (date) {
		return dateformat.toLocaleString(locale, options);
	}
}

function formatCodeDates(language, type = FORMATS.TYPES.DATE) {
	switch (language) {
		case 'en-US':
			return FORMATS.FORMATS['USA' + type];
		case 'sv-SE':
			return FORMATS.FORMATS['SWE' + type];
		case 'sw-KE':
			return FORMATS.FORMATS['SWA' + type];
		case 'pt-PT':
			return FORMATS.FORMATS['PRT' + type];
		case 'en-GB':
			return FORMATS.FORMATS['GBR' + type];
		default:
			break;
	}
}


function dateToString(date, removeTime = false) {
	let result;

	if (removeTime) {
		result = date.toISOString().replace('T', ' ').replace('Z', '');
		result = result.split(' ')[0];
	} else {
		result = date.toISOString().replace('T', ' ').replace('Z', '');
	}

	return result;
}

function parseDateOffset(timestampStr) {
	return new Date(timestampStr.getTime() - timestampStr.getTimezoneOffset() * 60 * 1000);
}

function parseDateUTC(date) {
	var b = date.split(/\D/);
	return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]));
}

function bytesToMegaBytes(bytes) {
	return bytes / (1024 * 1024);
}

function parseDate(date) {
	var b = date.split(/\D/);
	return new Date(b[0], --b[1], b[2], b[3], b[4], b[5]);
}

function setDateTimeToDate(date, dateTime) {
	date.setHours(dateTime.getHours());
	date.setMinutes(dateTime.getMinutes());
	date.setSeconds(dateTime.getSeconds());

	return date;
}

function addMinutesToDate(date, minutes) {
	const newDate = new Date(date.getTime());

	newDate.setMinutes(date.getMinutes() + minutes);

	return newDate;
}

function formatTableDate(date) {
	return date !== null ? date.slice(0, -3) : '';
}

function luhn(str) {
	let v = 0;
	let sum = 0;

	for (var i = 0; i < str.length; i++) {
		v = parseInt(str[i]);
		v *= 2 - (i % 2);
		if (v > 9) {
			v -= 9;
		}
		sum += v;
	}

	return parseInt(Math.ceil(sum / 10) * 10 - sum);
}

function testDate(year, month, day) {
	var validDate = false;
	try {
		let date = new Date(year + '-' + month + '-' + day).toISOString().slice(0, 10);
		if (month.length < 2) {
			month = '0' + month;
		}
		if (day.length < 2) {
			day = '0' + day;
		}

		var splitDate = date.split('-');

		validDate = !(
			parseInt(splitDate[0]) !== parseInt(year) ||
			parseInt(splitDate[1]) !== parseInt(month) ||
			parseInt(splitDate[2]) !== parseInt(day)
		);
	} catch (err) {
		// Pass.
		console.log('catch');
		console.log(err);
		return false;
	}

	return validDate;
}

function swedish_ssn(value) {
	if (typeof value === 'undefined' || value === null || value.length == 0) {
		return true;
	}
	if (!Number.isInteger(value) && !typeof value === 'string') {
		return false;
	}

	var reg = /^(\d{2}){0,1}(\d{2})(\d{2})(\d{2})([\-|\+]{0,1})?(\d{3})(\d{0,1})$/gm;

	var match = reg.exec(value);

	if (typeof match === 'undefined' || match === null || match[0].split('').length < 7) {
		return false;
	}

	let century = match[1];
	let year = match[2];
	let month = match[3];
	let day = match[4];
	let sep = match[5];
	let num = match[6];
	let check = match[7];

	var valid = luhn(year + month + day + num) === parseInt(check);
	if (valid && testDate(century + year, month, day)) {
		return true;
	}

	return valid && testDate(century + year, month, day) ? true : false;
}

function tanzanian_nida(value) {
	if (typeof value === 'undefined' || value === null || value.length == 0) {
		return true;
	}

	const format1RegExp = /^(\d{8})\-(\d{5})\-(\d{5})\-(\d{1,2})$/;
	const format2RegExp = /^(\d{8})(\d{11,12})$/;

	const nida = value.replace(/-/g, '');
	if (nida.length < 19 || nida.length > 20) {
		return false;
	}

	const match = value.match(format1RegExp) || value.match(format2RegExp);

	if (match) {
		// Extract the birthdate from the match and check if it's valid
		const birthdate = match[1];
		const year = birthdate.substring(0, 4);
		const month = birthdate.substring(4, 6);
		const day = birthdate.substring(6, 8);
		const date = new Date(`${year}-${month}-${day}`);
		if (isNaN(date.getTime())) {
			return false;
		}
		return true;
	}

	return false;

	// let date, district, ward, sequence;

	// if (format1RegExp.test(value)) {
	// 	[date, district, ward, sequence] = value.split('-');
	// } else if (format2RegExp.test(value)) {
	// 	date = value.substring(0, 8);
	// 	district = value.substring(8, 13);
	// 	sequence = value.substring(13);
	// 	ward = sequence.substring(0, 5);
	// 	sequence = sequence.substring(5);
	// } else {
	// 	return false;
	// }

	// // Check if the date is valid
	// const year = parseInt(date.substring(0, 4));
	// const month = parseInt(date.substring(4, 6));
	// const day = parseInt(date.substring(6, 8));
	// const isValidDate = testDate(year, month, day);
	// if (!isValidDate) {
	// 	return false;
	// }

	// // Apply the Luhn algorithm to the district, ward, and sequence parts
	// const isValidDistrict = luhn(district) === parseInt(district.charAt(4));
	// const isValidWard = luhn(ward) === parseInt(ward.charAt(4));
	// const isValidSequence = luhn(sequence) === parseInt(sequence.charAt(2));
	// if (!isValidDistrict || !isValidWard || !isValidSequence) {
	// 	return false;
	// }

	// return true;
}

function openPdfFIle(fileURL, fileName) {
	if (isMobileSize()) {
		const a = document.createElement('a');
		a.href = fileURL;
		a.download = fileName || 'document.pdf';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	} else {
		if (isChrome) {
			window.open(fileURL + '#view=fit');
		} else {
			window.open(fileURL);
		}
	}
}

function isMobileSize() {
	return (window.orientation === (90 || -90) && window.screen.height < 960) || (window.orientation === 0 && window.screen.width < 960);
}

// set up the possible functions:
const my_ssn_validations = {
	swedish_ssn: (value) => {
		return swedish_ssn(value);
	},
	tanzanian_nida: (value) => {
		return tanzanian_nida(value);
	},
};
// execute the one specified in the 'funcToRun' variable:
//   my_ssn_validations[funcToRun]();

function escapeRegExp(string) {
	return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
	return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

async function printDocument(file_name, element_id, callback, download = false, loading, filter = false) {
	const input = document.getElementById(element_id);

	await domtoimage
		.toJpeg(input, { ...(filter && { filter: filter }) })
		.then(function (dataUrl) {
			const pdf = new jsPDF('p', 'px', 'a4');
			var pdfWidth = pdf.internal.pageSize.getWidth();
			var pdfHeight = pdf.internal.pageSize.getHeight();
			var marginX = 10;
			var maxWidth = pdfWidth - marginX * 2;
			var ratio = pdfWidth / (input.offsetWidth - marginX * 2);

			var heightLeft = input.offsetHeight * ratio;

			var position = 0;
			pdf.addImage(dataUrl, 'JPEG', 10, 10, maxWidth, input.offsetHeight * ratio);
			heightLeft -= pdfHeight - 10;
			while (heightLeft >= 0) {
				position = heightLeft - input.offsetHeight * ratio;
				console.log(position);
				pdf.addPage();
				pdf.addImage(dataUrl, 'JPEG', 10, position, maxWidth, input.offsetHeight * ratio);
				heightLeft -= pdfHeight;
			}
			// pdf.output('dataurlnewwindow');
			if (callback) {
				callback(pdf.output('blob', { filename: file_name + '.pdf' }));
			}
			if (download) {
				pdf.save(file_name ? file_name + '.pdf' : 'selfcare.pdf');
			}
			if (loading) {
				loading();
			}
		})
		.catch(function (error) {
			console.error('oops, something went wrong!', error);
      
      if (loading) {
				loading();
			}
		});

	return;
}

function convertHex(hexCode, opacity) {
	var hex = hexCode.replace('#', '');

	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}

	var r = parseInt(hex.substring(0, 2), 16),
		g = parseInt(hex.substring(2, 4), 16),
		b = parseInt(hex.substring(4, 6), 16);

	return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
}

function LightenDarkenColor(col, amt) {
	var usePound = false;

	if (col[0] == '#') {
		col = col.slice(1);
		usePound = true;
	}

	var num = parseInt(col, 16);

	var r = (num >> 16) + amt;

	if (r > 255) r = 255;
	else if (r < 0) r = 0;

	var b = ((num >> 8) & 0x00ff) + amt;

	if (b > 255) b = 255;
	else if (b < 0) b = 0;

	var g = (num & 0x0000ff) + amt;

	if (g > 255) g = 255;
	else if (g < 0) g = 0;

	return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

function exitElementFullscreen() {
	if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		} else {
			console.log('Fullscreen API is not supported.');
		}
	}
}

function convertWeight(weight, unitSetting, translateUnits, goalShow) {
	if (unitSetting == '0') {
		const totalKg = weight / 1000; // Convert grams to kilograms
		if (translateUnits) {
			return `${totalKg.toFixed(1)} kg`;
		} else {
			return `${totalKg.toFixed(1)}`;
		}
	} else if (unitSetting == '1') {
		const totalKg = weight / 1000; // Convert grams to kilograms
		const totalStones = totalKg / 6.35029318;

		const wholeStones = Math.floor(totalStones);
		const remainingPounds = (totalStones - wholeStones) * 14;
		const wholePounds = Math.floor(remainingPounds);

		if (translateUnits && !goalShow) {
			return `${wholeStones} stones ${wholePounds} pounds`;
		} else if (goalShow && !translateUnits) {
			return `${wholeStones}.${wholePounds}`;
		} else {
			return `${wholeStones}sts, ${wholePounds}lb`;
		}
	} else {
		throw new Error("Invalid unit setting. Supported options are 'kg' and 'stones'.");
	}
}

function getCatalogueReduxProps(state, { translations, redux }, translate) {
	return {
		...(redux &&
			Object.keys(redux).reduce(
				(accumulator, currentValue) => ({ [currentValue]: redux[currentValue].split('.').reduce((o, i) => o && o[i], state) }),
				{}
			)),
		...(translations &&
			Object.keys(translations).reduce((accumulator, currentValue) => ({ [currentValue]: translate(translations[currentValue]) }), {})),
	};
}

function reduceNestedArrayToFlattenObject({ arr, key_name = 'id', value_key = 'value', buildkey = false }) {
	return arr.reduce((result, item) => {
		console.log(item);
		// Add the current item's ID and answer to the result
		if (item && item[key_name]) {
			if (buildkey) {
				result[buildkey(item[key_name])] = item[value_key];
			} else {
				result[item[key_name]] = item[value_key];
			}

			// If the item has children, recursively flatten them
			if (item.children && item.children.length > 0) {
				const childResult = reduceNestedArrayToFlattenObject({ arr: item.children, key_name, value_key, buildkey });
				result = { ...result, ...childResult };
			}
		}

		return result;
	}, {});
}

function replaceObjectInNestedArray({ arr, key, value, replacement }) {
	// Iterate through each object in the arr
	for (let i = 0; i < arr.length; i++) {
		const obj = arr[i];
		console.log(obj);
		// Check if the current object has the specified key and value
		if (obj[key] && obj[key] === value) {
			arr[i] = replacement; // Replace the object
		}

		// If the current object has a nested array of objects, recursively search within it
		if (Array.isArray(obj.children) && obj.children.length > 0) {
			let aux = replaceObjectInNestedArray({ arr: obj.children, key, value, replacement });
			if (aux && arr[i] && arr[i].children) {
				arr[i].children = aux;
			}
		}
	}

	// Return the modified array
	return arr;
}

function findObjectInNestedArray({ arr, key, value }) {
	// Iterate through each object in the arr
	for (let i = 0; i < arr.length; i++) {
		const obj = arr[i];

		// Check if the current object has the specified key and value
		if (obj[key] === value) {
			return obj; // If found, return the object
		}

		// If the current object has a nested array of objects, recursively search within it
		if (Array.isArray(obj.children) && obj.children.length > 0) {
			const nestedResult = findObjectInNestedArray(obj.children, key, value);
			if (nestedResult) {
				return nestedResult; // If found in nested array, return the object
			}
		}
	}

	// If not found, return null
	return null;
}

export {
	parseDateOffset,
	setDateTimeToDate,
	dateToString,
	parseDate,
	parseDateUTC,
	addMinutesToDate,
	formatDateRemoveTime,
	formatTableDate,
	swedish_ssn,
	openPdfFIle,
	isMobileSize,
	replaceAll,
	printDocument,
	convertHex,
	LightenDarkenColor,
	bytesToMegaBytes,
	my_ssn_validations,
	exitElementFullscreen,
	getByTimezone,
	formatLocaleDate,
	formatCodeDates,
	convertWeight,
	getCatalogueReduxProps,
	reduceNestedArrayToFlattenObject,
	findObjectInNestedArray,
	replaceObjectInNestedArray,
};
