/**User origins */
const UNKNOWN = 0;
const SYNCRONIZED = 1;
const MANUAL = 2;
const INVITED = 3;

/**User types */
const CUSTOMER = 0;
const STAFF = 1;
const EXTERNAL = 2;

export default {
	ORIGINS: {
		UNKNOWN,
		SYNCRONIZED,
		MANUAL,
		INVITED,
	},
	TYPES: {
		CUSTOMER,
		STAFF,
		EXTERNAL,
	},
};
