import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Typography, Link, Grid, TextField, Container, MenuItem, Button } from '@mui/material';
import { isMobileSize } from '../../helpers/functions/functions';

import {
	loginBankIDAction,
	checkBankIdProgress,
	bankIDQRGeneratorAction,
	handleSelectClientAction,
	resetBankIDProgress,
	loginAutostartBankIDAction,
	createSessionAction,
} from './actions/loginActions';
import { bankIDQRGeneratorMeetingAction } from '../Meetings/actions/meetingsActions';

import { Refresh as RefreshIcon } from '@mui/icons-material';

import BankIDlogo from '../../assets/images/bankid.png';

class LoginBankID extends Component {
	constructor(props) {
		super(props);

		this.state = {
			session_id: null,
			status: 'pending',
			QRImage: null,
			autoStartUrl: null,
		};

		this.pollSession = null;
	}
	componentDidMount() {
		this.props.resetBankIDProgress();

		this.createSessionAction();
	}

	createSessionAction = () => {
		this.props.createSessionAction({
			language: this.state.selectedLanguage,
			client_id: this.props.client.id,
			callback: (data) => {
				this.setState({
					QRImage: 'data:image/svg+xml;base64,' + data.QRCode,
					autoStartUrl: 'https://app.bankid.com/?autostarttoken=' + data.autoStartToken + '&redirect=',
					session_id: data.sessionId,
					status: data.status,
				});

				this.pollSession = setInterval(() => {
					if (data.sessionId) {
						this.props.checkBankIdProgress((data) => {
							if (data.status === 'failed') {
								clearInterval(this.pollSession);
								this.setState({ QRImage: null, status: 'failed', autoStartUrl: null });
							} else {
								this.setState({
									QRImage: 'data:image/svg+xml;base64,' + data.QRCode,
									autoStartUrl: 'https://app.bankid.com/?autostarttoken=' + data.autoStartToken + '&redirect=',
									session_id: data.sessionId,
									status: data.status,
								});
							}
						});
					} else {
						clearInterval(this.pollSession);
					}
				}, 3000);
			},
		});
	};

	componentWillUnmount() {
		clearInterval(this.pollSession);
		this.props.resetBankIDProgress();
	}

	state = {
		ssn: '',
		selectedLanguage: this.props.selectedLanguage,
		refreshQrcodeError: false,
	};

	render() {
		const { translate } = this.props;

		return (
			<React.Fragment>
				{this.props.login.platform === 'backoffice' ? (
					<Container component="main" maxWidth="sm" className="generic-form-main-no-margin">
						<TextField
							fullWidth={true}
							select
							label={this.props.translate('login_select_client_backoffice')}
							value={this.props.login.client !== null && this.props.login.client.id}
							onChange={(value) => {
								this.props.handleSelectClientAction(
									this.props.settings.clients.find((client) => client.id === value.target.value),
									'backoffice',
									'bankID'
								);

								this.setState({
									refreshQrcodeError: false,
								});
							}}
							margin="normal"
							variant="outlined"
						>
							{this.props.settings.clients.map((client) => (
								<MenuItem key={client.id} value={client.id}>
									{client.name}
								</MenuItem>
							))}
						</TextField>
					</Container>
				) : null}
				<Grid container spacing={2}>
					<input id="deviceid" name="deviceid" type="hidden" />
					{!isMobileSize() && (
						<Grid item xs={12} md={12}>
							{this.state.QRImage !== null ? (
								<img className="generic-square-edges-image" src={this.state.QRImage} />
							) : (
								<img src={BankIDlogo} height="70" alt="BankID logo" />
							)}
						</Grid>
					)}
					{!isMobileSize() && this.state.status === 'failed' && (
						<Grid item xs={12} md={12}>
							<Button
								color="secondary"
								variant="contained"
								size="small"
								className="login-bankid-button-refresh"
								onClick={(e) => {
									if (this.props.client !== null && this.props.client.id !== 0) {
										this.createSessionAction();
									}
								}}
							>
								{translate('login_bankid_button_refresh')}
								<RefreshIcon />
							</Button>
						</Grid>
					)}
					{!isMobileSize() && this.state.autoStartUrl && (
						<Grid item xs={12} md={12}>
							<hr className="generic-hr-text" data-content={translate('login_bankid_or_separator')} />
						</Grid>
					)}
					{this.state.autoStartUrl && (
						<Grid item xs={12} md={12}>
							<Button
								variant="contained"
								color={'primary'}
								href={this.state.autoStartUrl}
								// onClick={() => this.props.loginAutostartBankIDAction(this.state.selectedLanguage)}
							>
								{translate('login_bankid_start_app_button')}
							</Button>
						</Grid>
					)}
					<Grid item xs={12} md={12}>
						<Container component="main" maxWidth="sm">
							<Typography align="justify" variant="body2" component="p" gutterBottom>
								{translate('user_register_banck_id_info')}
							</Typography>
						</Container>
					</Grid>
					{this.props.type == 'login' && (
						<Grid item xs={12} md={12}>
							<Link
								href="#"
								variant="body2"
								onClick={() => {
									this.props.handleSelectClientAction(null, null);
								}}
							>
								&crarr; {translate('login_form_back_to_client_selection')}
							</Link>
						</Grid>
					)}
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	bankid_progress: state.login.bankid_progress,
	autostarttoken: state.login.autostarttoken,
	login: state.login,
	translate: getTranslate(state.localize),
	settings: state.settings,
	client: state.login.client,
});

export default connect(mapStateToProps, {
	loginBankIDAction,
	checkBankIdProgress,
	bankIDQRGeneratorAction,
	handleSelectClientAction,
	bankIDQRGeneratorMeetingAction,
	resetBankIDProgress,
	loginAutostartBankIDAction,
	createSessionAction,
})(LoginBankID);
