import axios from 'axios';
import { getTranslate } from 'react-localize-redux';
import {
	FETCH_TICKET,
	UPDATE_TICKET,
	CHANGE_OVERVIEW_COMPONENT,
	TICKET_PAYMENT,
	FETCH_CARE_TEAM,
	FETCH_TICKETS_DATA,
	FETCH_TICKET_QUESTIONNAIRE,
	TICKET_DIAGNOSIS,
	FETCH_UNREAD_TICKETS_DATA,
	SAVE_DIAGNOSIS,
	FETCH_HISTORY_RECORD,
	SET_UNREAD_TICKET_DATA,
	CLEAR_TICKET_DATA,
	CLEAR_TICKETS_LIST,
} from './types';
import { HANDLE_TABLE_LOADING } from '../../Generic/actions/types';
import { store } from '../../../store.js';
import eventsService from '../../../helpers/Events';
import {
	convertToFormData,
	handleLoadingAction,
	addDelayAction,
	closeLightboxAction,
	displayModalAction,
	handleSplashScreenAction,
	setProgressValueAction,
	displayProgressAction,
	closeProgressAction,
} from '../../Generic/actions/genericActions';
import { setPromptDialogIsDirty } from '../../Generic/actions/promptActions';
import { setTableCountAction } from '../../Generic/actions/tableActions';
import { fetchQuestionnaireAction } from '../../Questionnaires/actions/questionnairesActions';
import { reset } from 'redux-form';
import { push } from 'connected-react-router';
import { openPdfFIle, dateToString, parseDateOffset } from '../../../helpers/functions/functions';
import { deleteLibraryDraftAction } from '../../Libraries/actions/librariesActions';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import { handleError, apiRequest } from '../../../helpers/functions/request';
import { logoutAction } from '../../Login/actions/loginActions';
import TICKET from '../../../helpers/ticket';
import { FETCH_TAGGED_FILES, FETCH_MY_FILES } from '../../Generic/actions/types';
import ANALYTIC from '../../../helpers/analytic_logs';
import { createAnalyticAction } from '../../Analytic/actions/analyticActions.js';
import { getTicketSurveysAction, getUserTicketSurveysAction } from '../../Surveys/actions/surveyActions.js';

export const fetchTicketsAction =
	(table_id, config, pagination = {}, filters = {}) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		var params = { ...pagination, ...filters };

		var columns = [];

		config.map((field) => {
			if (field.options.renderOnExpand) {
				field.options.display = 'excluded';
			}
			columns.push({
				name: field.label,
				label: translate('table_column_' + field.label),
				options: {
					...field.options,
				},
			});
		});

		dispatch({
			type: FETCH_TICKETS_DATA,
			payload: {
				columns: columns,
				data: [],
				count: 0,
			},
		});

		dispatch({
			type: HANDLE_TABLE_LOADING,
			payload: { table_id, loading: true },
		});

		setTimeout(() => {
			axios
				.get(state.config.apihost + `/tickets`, {
					params,
				})
				.then((res) => {
					dispatch({
						type: HANDLE_TABLE_LOADING,
						payload: { table_id, loading: false },
					});

					dispatch({
						type: FETCH_TICKETS_DATA,
						payload: {
							columns: columns,
							data: res.data.items,
							count: res.data.total_items,
						},
					});

					dispatch(setTableCountAction(table_id, res.data.total_items));
				})
				.catch((err) => {
					if (err.request.status === 401) {
						dispatch(logoutAction(true, true, translate('inactivity_logout_info_message')));
					} else {
						dispatch({
							type: HANDLE_TABLE_LOADING,
							payload: { table_id, loading: false },
						});

						console.log(err);
						console.log('Error fetching data.');
					}
				});
		}, 1000);
	};

export const fetchTicketAction__V2 =
	(id, loading = true, callback = false, with_children = true) =>
	async (dispatch) => {
		if (loading) {
			dispatch(handleLoadingAction(true));
		}

		const state = store.getState();

		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/tickets/` + id,
			handle_error: (error, trigger_event) => {
				if (loading) {
					return dispatch(handleLoadingAction(false));
				}
			},
		});

		await dispatch({
			type: FETCH_TICKET,
			payload: response.data,
		});

		if (with_children) {
			await dispatch(fetchCareTeamAction__V2(response.data.id));
			await dispatch(fetchTicketDiagnosisAction__V2(response.data.id));
			if (response.data.scopes.includes(TICKET.SCOPES.OVERVIEW.SURVEYS_HISTORY)) {
				await dispatch(getUserTicketSurveysAction(id, response.data.users_id));
			} else {
				await dispatch(getTicketSurveysAction(id));
			}
		}

		if (callback) {
			callback(response.data);
		}

		if (loading) {
			return dispatch(handleLoadingAction(false));
		}

		return response;
	};

export const fetchTicketAction =
	(id, loading = true, callback = false, callback_error = false) =>
	async (dispatch) => {
		if (loading) {
			dispatch(handleLoadingAction(true));
		}

		const state = store.getState();

		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/tickets/` + id,
			handle_error: (error, trigger_event) => {
				if (loading) {
					dispatch(handleLoadingAction(false));
				}

				if (callback_error) {
					callback_error();
				} else {
					trigger_event();
				}
			},
		});

		if (response) {
			await dispatch({
				type: FETCH_TICKET,
				payload: response.data,
			});

			await dispatch(fetchCareTeamAction__V2(response.data.id, callback_error));
			await dispatch(fetchTicketDiagnosisAction__V2(response.data.id, callback_error));
			if (response.data.scopes.includes(TICKET.SCOPES.OVERVIEW.SURVEYS_HISTORY)) {
				await dispatch(getUserTicketSurveysAction(response.data.id, response.data.users_id));
			} else {
				await dispatch(getTicketSurveysAction(response.data.id));
			}
		}

		if (loading) {
			await dispatch(handleLoadingAction(false));
		}
		if (response) {
			if (callback) {
				callback(response.data);
			}
		}

		return;
	};

export const fetchTicketbyTokenAction =
	(token, loading = true, redirect = false, callback = false) =>
	async (dispatch) => {
		if (loading) {
			dispatch(handleSplashScreenAction(true));
		}

		const state = store.getState();

		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/tickets/token/` + token,
			handle_error: (error, trigger_event) => {
				console.log(loading);
				if (loading) {
					dispatch(handleSplashScreenAction(false));
				}

				dispatch(push(state.users.whoami.redirect_url ? state.users.whoami.redirect_url : '/dashboard'));
				trigger_event();
			},
		});

		await dispatch({
			type: FETCH_TICKET,
			payload: response.data,
		});

		await dispatch(fetchCareTeamAction__V2(response.data.id));
		await dispatch(fetchTicketDiagnosisAction__V2(response.data.id));
		if (response.data.scopes.includes(TICKET.SCOPES.OVERVIEW.SURVEYS_HISTORY)) {
			await dispatch(getUserTicketSurveysAction(response.data.id, response.data.users_id));
		} else {
			await dispatch(getTicketSurveysAction(response.data.id));
		}

		if (loading) {
			await dispatch(handleSplashScreenAction(false));
		}

		if (redirect) {
			return dispatch(handleFetchTicket(response.data));
		}
		if (callback) {
			callback();
		}

		return;
	};

export const updateTicketAction = (params) => async (dispatch) => {
	const state = store.getState();
	let ticket_id = params.id;

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/tickets/` + ticket_id,
		handle_error: (error, trigger_event) => {
			dispatch(push('/mycases'));

			trigger_event();
		},
	});

	await dispatch({
		type: UPDATE_TICKET,
		payload: response.data,
	});

	await dispatch(fetchCareTeamAction__V2(response.data.id, false, params.send_patient));
	return dispatch(fetchTicketDiagnosisAction__V2(response.data.id));
};

export const assignTicketPractitionerAction = (user_id) => (dispatch) => {
	const state = store.getState();

	var params = new FormData();
	params.append('user_id', user_id);

	axios
		.post(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/assign`, params)
		.then((res) => {
			dispatch(fetchTicketAction(state.tickets.ticket.id));
		})
		.catch((error) => {
			console.log(error);
		});
};

export const fetchCareTeamAction =
	(ticket_id = false, send_patient = false) =>
	(dispatch) => {
		const state = store.getState();

		if (!ticket_id) {
			ticket_id = state.tickets.ticket.id;
		}

		axios
			.get(state.config.apihost + `/tickets/` + ticket_id + `/careteam`, {
				...(send_patient && { params: { send_patient: send_patient } }),
			})
			.then((res) => {
				dispatch({
					type: FETCH_CARE_TEAM,
					payload: res.data,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
/**TODO: remove duplicated and deprecated functions */
export const fetchCareTeamAction__V2 =
	(ticket_id = undefined, callback_error = false, send_patient = false) =>
	async (dispatch) => {
		const state = store.getState();

		if (ticket_id === undefined) {
			ticket_id = state.tickets.ticket.id;
		}
		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/tickets/` + ticket_id + `/careteam`,
      ...(send_patient && { params: { send_patient: send_patient } }),
			handle_error: (error, trigger_event) => {
				if (callback_error) {
					callback_error();
				} else {
					trigger_event();
				}
			},
		});

		if (response) {
			return dispatch({
				type: FETCH_CARE_TEAM,
				payload: response.data,
			});
		}
		return;
	};

export const addUserToCareTeamAction =
	(user_id, user_data = null) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		var data = new FormData();
		data.append('user_id', user_id);

		if (user_data) {
			if (user_data.roles_id) {
				data.append('role_id', user_data.roles_id);
			}
			if (user_data.organization) {
				data.append('organization', user_data.organization);
			}
		}

		await apiRequest({
			method: 'post',
			url: state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/access`,
			data,
			submit_form: 'ticket_careteam_post',
			success_message: translate('alert_add_careteam'),
		});

		return dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	};

export const updateUserToCareTeamAction =
	(user_id, roles_id = null, callback = false) =>
	async (dispatch) => {
		const state = store.getState();

		await apiRequest({
			method: 'patch',
			url: state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/access/` + user_id,
			data: {
				roles_id,
			},
			submit_form: 'ticket_patient_update',
		});

		if (callback) {
			callback();
		}

		return dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	};

export const removeUserFromCareTeamAction = (user_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	await apiRequest({
		method: 'delete',
		url: state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/access/` + user_id,
		submit_form: 'ticket_patient_delete',
		success_message: translate('alert_remove_careteam'),
	});

	return dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
};

export const fetchTicketHistoryAction =
	(table_id, config, pagination = {}, filters = {}) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		var params = { ...pagination, ...filters };

		var columns = [];

		config.map((field) => {
			if (field.options.renderOnExpand) {
				field.options.display = 'excluded';
			}
			columns.push({
				name: field.label,
				label: translate('table_column_' + field.label),
				options: {
					...field.options,
				},
			});
		});

		dispatch({
			type: FETCH_TICKETS_DATA,
			payload: {
				columns: columns,
				data: [],
				count: 0,
			},
		});

		dispatch({
			type: HANDLE_TABLE_LOADING,
			payload: { table_id, loading: true },
		});
		setTimeout(() => {
			axios
				.get(state.config.apihost + `/auth/users/` + state.tickets.ticket.users_id + `/tickets`, {
					params,
				})
				.then((res) => {
					dispatch({
						type: HANDLE_TABLE_LOADING,
						payload: { table_id, loading: false },
					});

					dispatch({
						type: FETCH_TICKETS_DATA,
						payload: {
							columns: columns,
							data: res.data.data,
							count: res.data.total_count,
						},
					});

					dispatch(setTableCountAction(table_id, res.data.total_count));

					dispatch({
						type: FETCH_HISTORY_RECORD,
						payload: [],
					});
				})
				.catch((error) => {
					dispatch({
						type: HANDLE_TABLE_LOADING,
						payload: { table_id, loading: false },
					});

					console.log(error);
				});
		}, 1000);
	};

export const fetchHistoryRecordAction = (id) => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/` + id)
		.then((res) => {
			dispatch({
				type: FETCH_HISTORY_RECORD,
				payload: res.data,
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const fetchTicketQuestionnaireAction = (ticket_id, questionnaire_id) => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/` + ticket_id + `/questionnaires/` + questionnaire_id)
		.then((res) => {
			res.data.children.forEach((question) => {
				if (question.type === 'text') {
					question.answer = question.text;
				}
				question.triggered = typeof question.answer !== 'undefined' && question.answer.length > 0 ? true : false;
			});

			dispatch({
				type: FETCH_TICKET_QUESTIONNAIRE,
				payload: {
					questionnaire: res.data,
					completed: true,
				},
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const fetchTicketFollowupQuestionnaireAction = (ticket_id, questionnaire_id) => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/` + ticket_id + `/questionnaires/` + questionnaire_id)
		.then((res) => {
			res.data.questionnaire.children.forEach((question) => {
				question.answer = '';
				if (question.type === 'text') {
					question.answer = question.text;
				}
				question.triggered = question.parent_id === '0' ? true : false;
			});

			let followup_questionnaire = state.tickets.ticket.questionnaires.find((questionnaire) => questionnaire.id === questionnaire_id);

			if (followup_questionnaire.status === '0' && state.users.whoami.scopes.includes('customer')) {
				dispatch(handleLoadingAction(true));
			}

			dispatch({
				type: FETCH_TICKET_QUESTIONNAIRE,
				payload: {
					questionnaire: res.data.questionnaire,
					completed: followup_questionnaire.status === '0' ? false : true,
				},
			});

			if (followup_questionnaire.status === '0' && state.tickets.ticket.scopes.includes(TICKET.SCOPES.QUESTIONNAIRES.ANSWER)) {
				dispatch(handleLoadingAction(false));
				store.dispatch(push('/followup/tickets/' + ticket_id + '/questionnaire/' + questionnaire_id));
			} else {
				dispatch(handleLoadingAction(false));
				window.location.hash = '#';
				dispatch(changeOverviewComponentAction('questionnaires', followup_questionnaire.id));
				dispatch(fetchTicketQuestionnaireAction(ticket_id, questionnaire_id));
			}
		})
		.catch((error) => {
			console.log(error);
			dispatch(handleLoadingAction(false));
		});
};

export const fetchTicketDiagnosisAction = () => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/diagnoses`)
		.then((res) => {
			dispatch({
				type: TICKET_DIAGNOSIS,
				payload: res.data,
			});
			if (res.data.length > 0) dispatch(saveDiagnosisAction(res.data.map((v) => Number(v.diagnoses_id))));
		})
		.catch((error) => {
			console.log(error);
		});
};

export const fetchTicketDiagnosisAction__V2 =
	(ticket_id = false, callback_error = false) =>
	async (dispatch) => {
		const state = store.getState();

		if (!ticket_id) {
			ticket_id = state.tickets.ticket.id;
		}

		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/tickets/` + ticket_id + `/diagnoses`,
			handle_error: (error, trigger_event) => {
				if (callback_error) {
					callback_error();
				} else {
					trigger_event();
				}
			},
		});

		if (response) {
			await dispatch({
				type: TICKET_DIAGNOSIS,
				payload: response.data,
			});

			if (response.data.length > 0) {
				return dispatch(saveDiagnosisAction(response.data.map((v) => Number(v.diagnoses_id))));
			} else {
				return;
			}
		}
		return;
	};

export const changeOverviewComponentAction =
	(component = '', id = '', back_component = '') =>
	(dispatch) => {
		dispatch({
			type: CHANGE_OVERVIEW_COMPONENT,
			payload: {
				overview_component: component,
				overview_component_id: id,
				overview_component_back: back_component,
			},
		});
	};

const convertModelToFormData = (model, form, namespace) => {
	let formData = form || new FormData();

	for (let propertyName in model) {
		if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
		let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
		if (model[propertyName] instanceof Date) formData.append(formKey, model[propertyName].toISOString());
		else if (model[propertyName] instanceof Array) {
			model[propertyName].forEach((element, index) => {
				const tempFormKey = `${formKey}[${index}]`;
				convertModelToFormData(element, formData, tempFormKey);
			});
		} else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
			convertModelToFormData(model[propertyName], formData, formKey);
		else formData.append(formKey, model[propertyName].toString());
	}
	return formData;
};

export const postTicketAction =
	(form, params = null, loading = false) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		if (loading) {
			dispatch(handleSplashScreenAction(true));
		}

		let body;

		if (params) {
			body = { ...params };
		} else {
			body = {
				conditions_id: state.conditions.selected.id,
			};

			if (
				state.conditions.filter.group !== 'adult' &&
				state.settings.site.display_condition_filters &&
				state.settings.site.display_condition_filters
			) {
				body['ward'] = state.form[form].values;
			}
		}

		body = convertModelToFormData(body);

		axios
			.post(state.config.apihost + `/tickets/`, body, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then((res) => {
				dispatch({
					type: FETCH_TICKET,
					payload: res.data,
				});
				//TODO: remove settings params practitioners_id and conditions_id
				if (res.data.patient.status === '10' && res.data.has_ward) {
					dispatch(push('/case/new/ward/data'));
				}

				if (loading) {
					setTimeout(() => {
						dispatch(handleSplashScreenAction(false));
					}, 3000);
				}

				return res;
			})
			.then((res) => {
				if (!(res.data.patient.status === '10' && res.data.has_ward) && res.data.questionnaires.length > 0) {
					dispatch(fetchQuestionnaireAction({ questionnaire_id: res.data.questionnaires[0].questionnaires_id }, loading));
				}
				return res;
			})
			.then((res) => {
				if (!(res.data.patient.status === '10' && res.data.has_ward) && res.data.questionnaires.length > 0) {
					if (window.self !== window.top) {
						dispatch(push('/case/new/plain/' + res.data.id + '/questionnaires/' + res.data.questionnaires[0].id));
					} else {
						dispatch(push('/case/new/' + res.data.id + '/questionnaires/' + res.data.questionnaires[0].id));
					}
				}

				if (res.data.questionnaires.length == 0) {
					if (res.data.payment_status == '0' && res.data.condition.price != '0') {
						dispatch(push('/case/new/' + res.data.id + '/payment'));
					} else {
						dispatch(postPayment(res.data.id, '', true));
						dispatch(
							displayModalAction({
								title: translate('ticket_new_thank_you_title'),
								description: translate('ticket_new_thank_you_subtitle'),
								text: translate('ticket_new_thank_you_text', { patient: state.users.whoami.name }),
								button_no: translate('ticket_new_thank_you_button'),
								button_yes: translate('ticket_new_thank_you_go_to_case_button'),
								disable_click_away: true,
								action_no_button: () => {
									dispatch(push('/mycases'));
								},
								action: () => {
									dispatch(push('/case/' + state.tickets.ticket.token));
								},
								props: null,
							})
						);
					}
				}

				return res;
			})
			.then((res) => {
				if (!(res.data.patient.status === '10' && res.data.has_ward)) {
					if (window.self === window.top && res.data.questionnaires.length > 0) {
						dispatch(
							setPromptDialogIsDirty({
								is_dirty: true,
								path_allowed: ['/case/new/'],
								back_label: 'questionnaires_prompt_back_btn_label',
								next_label: 'questionnaires_prompt_next_btn_label',
							})
						);
					}

					if (loading) {
						dispatch(handleSplashScreenAction(false));
					}
					dispatch(handleLoadingAction(false));
				}
			})
			.catch((err) => {
				if (!loading) {
					dispatch(handleSplashScreenAction(false));
				}

				dispatch(
					addDelayAction(
						[
							handleLoadingAction(false),
							...(state.settings.params.conditions_id
								? [push(state.users.whoami.redirect_url ? state.users.whoami.redirect_url : '/dashboard')]
								: [null]),
						],
						() =>
							setTimeout(() => {
								eventsService.triggerEvent('alert', {
									type: 'error',
									message:
										Object.keys(err).length == 0
											? 'error'
											: typeof err.response.data.context.message !== 'undefined'
											? translate(err.response.data.context.message)
											: typeof err.response.data.message !== 'undefined'
											? translate(err.response.data.message)
											: err.message,
								});
							}, 1000)
					)
				);
			});
	};

export const handleIntiveTicketAction = (id) => (dispatch) => {
	const state = store.getState();

	dispatch(handleLoadingAction(true));

	axios
		.get(state.config.apihost + `/tickets/` + id)
		.then((res) => {
			dispatch({
				type: FETCH_TICKET,
				payload: res.data,
			});

			return res;
		})
		.then((res) => {
			if (res.data.questionnaires.length > 0) {
				dispatch(fetchQuestionnaireAction({ questionnaire_id: res.data.questionnaires[0].questionnaires_id }));
			} else if (res.data.payment_status == '0') {
				if (res.data.condition.price == '0') {
					dispatch(
						postPayment(res.data.id, '', true, () =>
							dispatch(push(res.scopes.includes(TICKET.SCOPES.MANAGE.VIEW) ? 'case' : '/dashboard'))
						)
					);
				} else {
					dispatch(push('/case/new/' + res.data.id + '/payment'));
				}
			}

			return res;
		})
		.then((res) => {
			if (res.data.questionnaires.length > 0) {
				if (window.self !== window.top) {
					dispatch(push('/case/new/plain/' + res.data.id + '/questionnaires/' + res.data.questionnaires[0].id));
				} else {
					dispatch(push('/case/new/' + res.data.id + '/questionnaires/' + res.data.questionnaires[0].id));
				}
			}

			return res;
		})
		.then((res) => {
			dispatch(handleLoadingAction(false));

			return res;
		})
		.then((res) => {
			if (res.data.questionnaires.length > 0 || (res.data.payment_status == '0' && res.data.condition.price != '0')) {
				if (window.self === window.top) {
					dispatch(
						setPromptDialogIsDirty({
							is_dirty: true,
							path_allowed: ['/case/new/'],
							back_label: 'questionnaires_prompt_back_btn_label',
							next_label: 'questionnaires_prompt_next_btn_label',
						})
					);
				}
			}
		})
		.catch((error) => {
			dispatch(handleLoadingAction(false));
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: error.message })));
			console.log(error);
		});
};

export const patchWardPatientAction = (form) => (dispatch) => {
	const state = store.getState();

	let body = state.form[form].values;

	if (typeof body['birthdate'] !== 'undefined') {
		body['birthdate'] = dateToString(
			new Date(
				body['birthdate'].toLocaleString('en-US', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					hour12: true,
				})
			)
		);
	}

	if (typeof body.first_name !== 'undefined' || typeof body.last_name !== 'undefined') {
		body['name'] = body.first_name + ' ' + body.last_name;
	}
	if (typeof body['consented'] !== 'undefined') {
		body['consented'] = body.consented ? '1' : '0';
	}

	body = convertModelToFormData(body);

	axios
		.patch(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/ward`, body, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(fetchTicketAction(state.tickets.ticket.id, false));

			dispatch(
				addDelayAction([
					fetchQuestionnaireAction({ questionnaire_id: state.tickets.ticket.questionnaires[0].questionnaires_id }),
					push('/case/new/' + state.tickets.ticket.id + '/questionnaires/' + state.tickets.ticket.questionnaires[0].id),
					setPromptDialogIsDirty({
						is_dirty: true,
						path_allowed: ['/case/new/'],
						back_label: 'questionnaires_prompt_back_btn_label',
						next_label: 'questionnaires_prompt_next_btn_label',
					}),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postPayment =
	(ticket_id, form, consent = false, callback = false) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		let payment_type = '';
		let data = '';

		if (consent) {
			payment_type = 'consent';
			data = convertToFormData({ payment_type: 'consent' });
		} else {
			payment_type = state.form[form].values.payment_type;
			data = convertToFormData(state.form[form].values);
		}

		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + `/tickets/` + ticket_id + `/payments`,
			data,
			params: { payment_type: payment_type },
			submit_form: form,
			success_message: translate('alert_post_ticket'),
		});

		await dispatch({
			type: TICKET_PAYMENT,
			payload: response.data,
		});

		await dispatch(setPromptDialogIsDirty({ is_dirty: false }));

		if (callback) {
			callback();
		} else {
			dispatch(
				displayModalAction({
					title: translate('ticket_new_thank_you_title'),
					description: translate('ticket_new_thank_you_subtitle'),
					text: translate('ticket_new_thank_you_text', { patient: state.users.whoami.name }),
					button_no: translate('ticket_new_thank_you_button'),
					button_yes: translate('ticket_new_thank_you_go_to_case_button'),
					disable_click_away: true,
					show_button_yes: response.data.scopes.includes('tickets.manage.view'),
					action_no_button: () => {
						dispatch(push(state.users.whoami.scopes.includes('lists.my_cases') ? '/mycases' : '/dashboard'));
					},
					action: () => {
						dispatch(push('/case/' + state.tickets.ticket.token));
					},
					props: null,
				})
			);
		}

		return;
	};

export const postFollowupAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	const start_minutes = state.form[form].values.activation.getMinutes();

	var start = parseDateOffset(state.form[form].values.activation).toISOString().split('.')[0] + 'Z';
	start = start.replace('T', ' ').replace('Z', '');

	var end = state.form[form].values.activation.setMinutes(start_minutes + Number(state.form[form].values.duration));
	end = parseDateOffset(state.form[form].values.activation).toISOString().split('.')[0] + 'Z';
	end = end.replace('T', ' ').replace('Z', '');

	var data = new FormData();

	data.append('activation', start);
	data.append('ends_at', end);
	data.append('duration', state.form[form].values.duration);
	data.append('conditions_id', state.form[form].values.conditions_id);
	data.append('assignedtome', state.form[form].values.assignedtome ? '1' : '0');
	data.append('message', state.form[form].values.message);

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/followups',
		data,
		success_message: translate('alert_post_followup'),
		submit_form: form,
	});

	await dispatch(
		createAnalyticAction(ANALYTIC.SCHEDULE_FOLLOWUP, 'tickets', state.tickets.ticket.id, {
			timestamp: start,
			condition: state.form[form].values.conditions_i,
		})
	);
	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');
};

export const postAppointmentAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	const start_minutes = state.form[form].values.starts_at.getMinutes();

	var start = parseDateOffset(state.form[form].values.starts_at).toISOString().split('.')[0] + 'Z';
	start = start.replace('T', ' ').replace('Z', '');

	var end = state.form[form].values.starts_at.setMinutes(start_minutes + Number(state.form[form].values.duration));
	end = parseDateOffset(new Date(state.form[form].values.starts_at)).toISOString().split('.')[0] + 'Z';
	end = end.replace('T', ' ').replace('Z', '');

	var data = new FormData();

	data.append('starts_at', start);
	data.append('ends_at', end);
	data.append('duration', state.form[form].values.duration);
	data.append('text', state.form[form].values.text);

	var users_arr = [];
	state.form[form].values.users.map((user, key) => {
		users_arr[key] = user.value;
	});

	data.append('invitees', users_arr.toString());

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/appointments',
		data,
		success_message: translate('alert_post_appointment'),
		submit_form: form,
	});

	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');
};

export const postRejectAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/reject',
		data,
		success_message: translate('alert_post_reject'),
		submit_form: form,
	});

	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');
};

export const patchReferenceVerifyAction = (form) => async (dispatch) => {
	const state = store.getState();

	await dispatch(patchTicketReferenceAction(form));
	await dispatch(verifyTicketAction(state.tickets.ticket.id));
	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');

	// translate('alert_patch_insurance_reference')
};

export const postNoteAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/notes',
		data,
		success_message: translate('alert_post_note'),
		submit_form: form,
	});

	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');
};

export const postAddQuestionnaireAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);
	const questionnaire_name = state.questionnaires.questionnaires.find(
		(questionnaire) => questionnaire.id == state.form[form].values.questionnaires_id
	)?.name;

	let data = convertToFormData(state.form[form].values);

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/questionnaires',
		data,
		submit_form: form,
		success_message: translate('alert_post_addquestionnaire'),
	});

	await dispatch(
		createAnalyticAction(ANALYTIC.SEND_QUESTIONNAIRE, 'tickets', state.tickets.ticket.id, {
			name: questionnaire_name,
		})
	);
	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');
};

/**
 * Post ticket chunked file request
 * @param {*} form
 * @returns
 */

export const postFileAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let auxChunk;
	let cnt = 0;

	let data = convertToFormData(state.form[form].values);

	const file = state.form[form].values.file[0];

	var tsize = file.size;
	var coffset = 0;

	var lastChunk = false;
	var csize = 1024 * 1024 * 10;
	csize = csize > tsize ? tsize : csize;

	let chunk = file.slice(0, csize);
	auxChunk = new File([chunk], file.name);

	data.append('tsize', tsize);
	data.append('csize', csize);
	data.append('coffset', coffset);

	data.set('file', auxChunk);

	const send = async () => {
		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files',
			data,
		});

		if (store.getState().generic.progress.canceled) {
			await dispatch(reset(form));
			return eventsService.triggerEvent('alert', {
				type: 'warning',
				message: translate('alert_post_libraries_file_upload_canceled'),
			});
		}

		if (parseInt(response.data.size) !== tsize) {
			cnt = cnt + 1;
			dispatch(setProgressValueAction((cnt * 100) / (tsize / csize)));

			data.set('coffset', parseInt(response.data.size) + 1);

			if (!data.has('uuid')) {
				data.append('uuid', response.data.uuid);
			}

			chunk = file.slice(parseInt(response.data.size), parseInt(response.data.size) + csize);
			auxChunk = new File([chunk], file.name);

			data.set('csize', auxChunk.size);
			data.set('file', auxChunk);

			await send();
		} else {
			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') });
			await dispatch(setProgressValueAction(100));
			await dispatch(
				createAnalyticAction(ANALYTIC.UPLOAD_FILE, 'tickets', state.tickets.ticket.id, {
					filename: response.data.name,
				})
			);
			await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			await dispatch(closeProgressAction());
			await dispatch(reset(form));
			return eventsService.triggerEvent('tab');
		}
	};

	dispatch(
		displayProgressAction({
			title: translate('file_form_progress_modal_title'), //File upload in progress
			description: translate('file_form_progress_modal_description', {
				size: (Math.pow(10, -6) * tsize).toFixed(1),
				file_name: file.name,
			}),
			button_cancel: translate('generic_button_cancel'),
			button_cancel_id: 'generic-progress-modal-cancel-button',
			// props: data,
		})
	);

	return send();
	// await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

	// await dispatch(reset(form));

	// return eventsService.triggerEvent('tab');
};

export const deleteFilesAction = (uuid) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/files/` + uuid)
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_delete_file') })));
		})
		.catch((err) => {
			handleError(err);
		});
};

export const postQuestionnairePDFAction = (pdf, filename, callback) => (dispatch) => {
	dispatch(handleLoadingAction(true));
	const state = store.getState();
	const translate = getTranslate(state.localize);
	const file = new File([pdf], filename);

	var data = new FormData();
	data.append('type', 'upload');
	data.append('upload', file);

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files', data, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then(() => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction([
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') }),
					() => eventsService.triggerEvent('tab'),
					callback,
					handleLoadingAction(false),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postScreenShotAction = () => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData({
		type: 'screenshot',
		upload: [state.generic.lightbox.screenshot],
	});

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files', data, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then(() => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(closeLightboxAction());
			dispatch(
				addDelayAction(null, [() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') })])
			);
		})
		.catch((err) => {
			dispatch(closeLightboxAction());
			eventsService.triggerEvent('alert', { type: 'error', message: err.message });
		});
};

export const postUploadFileChatAction = (data) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files',
		data,
		submit_form: 'ticket_upload_file_chat',
		success_message: translate('alert_post_uploadfile'),
	});

	return dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
};

export const postAlertAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/alerts',
		data,
		submit_form: form,
		success_message: translate('alert_post_alert'),
	});

	await dispatch(createAnalyticAction(ANALYTIC.CREATE_OBSERVANDUM, 'tickets', state.tickets.ticket.id, { text: state.form[form].values.text }));
	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');
};

export const postCertificateAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	const { template, ...values } = state.form[form].values;

	let data = {
		draft: 0,
		template: template,
		values: JSON.stringify(values),
	};

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/certificates', convertToFormData(data), {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction(reset(form), [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_certificate') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const previewCertificateAction = (form) => () => {
	const state = store.getState();

	const { template, ...values } = state.form[form].values;

	let data = {
		draft: 1,
		template: template,
		values: JSON.stringify(values),
	};

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/certificates', convertToFormData(data), {
			responseType: 'blob',
			headers: { Accept: 'application/pdf', 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			const fileURL = URL.createObjectURL(res.data);
			openPdfFIle(fileURL);
		})
		.catch((err) => {
			eventsService.triggerEvent('alert', { type: 'error', message: err.message });
		});
};

export const postPrescriptionAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	const { ...values } = state.form[form].values;

	let data = {
		draft: 0,
		values: JSON.stringify(values),
	};

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/prescriptions',
		data: convertToFormData(data),
		submit_form: form,
		success_message: translate('alert_post_prescription'),
	});

	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');
};

export const previewPrescriptionAction = (form) => async () => {
	const state = store.getState();

	const { ...values } = state.form[form].values;

	let data = {
		draft: 1,
		values: JSON.stringify(values),
	};

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/prescriptions',
		data: convertToFormData(data),
		submit_form: form,
		headers: { Accept: 'application/pdf', 'Content-Type': 'multipart/form-data' },
		responseType: 'blob',
	});

	const fileURL = URL.createObjectURL(response.data);

	return openPdfFIle(fileURL);
};

export const postDiagnosesAction = (diagnoses) => (dispatch) => {
	const state = store.getState();

	var diagnosis = { diagnoses_id: JSON.stringify(diagnoses) };

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/diagnoses', convertToFormData(diagnosis), {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
		})
		.catch((err) => {
			eventsService.triggerEvent('alert', { type: 'error', message: err.message });
		});
};

export const saveDiagnosisAction = (diagnosis_to_save) => (dispatch) => {
	dispatch({
		type: SAVE_DIAGNOSIS,
		payload: diagnosis_to_save,
	});
};

export const postSaveCloseTicketAction = (form, close) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	if (close) {
		state.form[form].values.status = TICKET.STATUS.CLOSED;
	}

	Object.keys(state.form[form].values).forEach(
		(key) => (state.form[form].values[key] === null || state.form[form].values[key].length === 0) && delete state.form[form].values[key]
	);

	var data = convertToFormData(state.form[form].values);

	await apiRequest({
		method: 'patch',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id,
		data,
		submit_form: form,
		success_message: close ? translate('alert_post_closeticket') : translate('alert_post_saveticket'),
	});
	await dispatch(createAnalyticAction(ANALYTIC.CLOSE_CASE, 'tickets', state.tickets.ticket.id));
	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

	return eventsService.triggerEvent('tab');
};

export const closeTicketAction = () => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData({
		status: TICKET.STATUS.CLOSED,
	});

	await apiRequest({
		method: 'patch',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id,
		data,
		submit_form: 'ticket_close_modal_form',
		success_message: translate('alert_post_closeticket'),
	});

	return dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
};

export const reactivateTicketAction = () => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData({
		status: TICKET.STATUS.ACTIVE,
	});

	await apiRequest({
		method: 'patch',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id,
		data,
		success_message: translate('alert_post_reactivate'),
	});

	return dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
};

export const claimTicketAction = (ticket_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + ticket_id + '/claim',
		// submit_form: 'ticket_claim_modal_form',
		success_message: translate('alert_post_claimed_ticket'),
	});

	return dispatch(push('/case/' + response.data.token));
};

export const verifyTicketModalAction =
	({ ticket_id, ticket_token }) =>
	async (dispatch) => {
		await dispatch(verifyTicketAction(ticket_id));

		return dispatch(push('/case/' + ticket_token));
	};

export const verifyTicketAction = (ticket_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	return await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + ticket_id + '/verify',
		// submit_form: 'ticket_verify_modal_form',
		success_message: translate('alert_post_verify'),
	});
};

export const fetchUnreadTicketsAction = () => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/unread`)
		.then((res) => {
			dispatch({
				type: FETCH_UNREAD_TICKETS_DATA,
				payload: res.data,
			});
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const postShareSelfCareFileAction = (data) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/attachment',
		data,
		submit_form: 'share_seflcare_library_ticket',
		success_message: translate('alert_post_shareselfcarefile'),
	});

	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

	return eventsService.triggerEvent('tab');
};

export const postShareSelfCarePdfFileLibrariesAction = (ticket_id, file) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);
	console.log(file);
	// const response = await apiRequest({
	// 	method: 'get',
	// 	url: state.config.driveshost + file.link,
	// });

	var data = new FormData();

	data.append('ref_type', 'tickets');
	data.append('ref_id', ticket_id);

	const response = await apiRequest({
		method: 'post',
		url: state.config.driveshost + file.link + '/shares',
		data,
		submit_form: 'share_seflcare_pdf_library_ticket',
		success_message: translate('alert_post_uploadfile'),
	});

	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

	return eventsService.triggerEvent('tab');
};

export const postShareSelfCareEditableFileLibrariesAction =
	(form, handleClose, draft_id = false) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		const input = document.getElementById('viewer-content');

		domtoimage
			.toJpeg(input)
			.then(function (dataUrl) {
				const pdf = new jsPDF('p', 'px', 'a4');

				var pdfWidth = pdf.internal.pageSize.getWidth();
				var pdfHeight = pdf.internal.pageSize.getHeight();

				var marginX = 10;

				var maxWidth = pdfWidth - marginX * 2;

				var ratio = pdfWidth / (input.offsetWidth - marginX * 2);

				pdf.addImage(dataUrl, 'toJpeg', 10, 10, maxWidth, input.offsetHeight * ratio);
				var blob = pdf.output('blob');

				var f = new File([blob], state.form[form].values.name, { type: 'application/pdf' });

				var body = new FormData();

				body.append('upload', f);
				body.append('type', 'selfcare');

				axios
					.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files', body, {
						headers: { 'Content-Type': 'multipart/form-data' },
					})
					.then(() => {
						dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

						if (draft_id) {
							dispatch(deleteLibraryDraftAction(draft_id));
						}

						dispatch(
							addDelayAction(
								() => handleClose(),
								[
									() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') }),
									() => eventsService.triggerEvent('tab'),
								]
							)
						);
					})
					.catch((err) => {
						dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
					});
			})
			.catch(function (error) {
				dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: error })));
			});
	};

export const updateTicketData = (ticket_id, params) => (dispatch) => {
	const state = store.getState();

	axios
		.patch(state.config.apihost + `/tickets/` + ticket_id, params)
		.then((res) => {
			// dispatch({
			// 	type: UPDATE_TICKET,
			// 	payload: res.data,
			// });
		})
		.catch((error) => {
			console.log(error);
		});
};

export const changeTicketResponsibleAction = (user_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData({
		user_id: user_id,
	});

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/responsible',
		data,
		submit_form: 'ticket_responsible_update',
		success_message: translate('alert_change_careteam_responsible'),
	});

	return dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
};

export const addExternalPersonAction =
	(form, user = null) =>
	async (dispatch) => {
		if (user) {
			return dispatch(patchExternalUserAction(form));
		} else {
			return dispatch(postExternalUserAction(form));
		}
	};

export const postExternalUserAction = (form) => async (dispatch) => {
	const state = store.getState();

	if (typeof state.form[form].values.birthdate !== 'undefined') {
		state.form[form].values.birthdate = dateToString(
			new Date(
				state.form[form].values.birthdate.toLocaleString('en-US', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					hour12: true,
				})
			)
		);
	}

	state.form[form].values.origin = 0;

	if (typeof state.form[form].values.consented !== 'undefined') {
		state.form[form].values.consented = state.form[form].values.consented ? '1' : '0';
	}

	let data = convertToFormData(state.form[form].values);

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/users`,
		data,
		submit_form: form,
	});

	await dispatch(addUserToCareTeamAction(response.data.id, state.form[form].values));
	await dispatch(
		createAnalyticAction(ANALYTIC.ADD_MEMBER_CARETEAM, 'tickets', state.tickets.ticket.id, { name: state.form[form].values.first_name })
	);
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');
};

export const patchExternalUserAction = (form) => async (dispatch) => {
	const state = store.getState();

	if (typeof state.form[form].values.consented !== 'undefined') {
		state.form[form].values.consented = state.form[form].values.consented ? '1' : '0';
	}

	let data = convertToFormData(state.form[form].values);

	const response = await apiRequest({
		method: 'patch',
		url: state.config.apihost + `/users/` + state.form[form].values.user_id,
		data,
		submit_form: form,
	});

	await dispatch(addUserToCareTeamAction(response.data.id, state.form[form].values));
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');
};

export const postTicketInviteUserAction =
	(form, callback = false) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		if (typeof state.form[form].values.birthdate !== 'undefined') {
			state.form[form].values.birthdate = dateToString(
				new Date(
					state.form[form].values.birthdate.toLocaleString('en-US', {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						hour12: true,
					})
				)
			);
		}

		state.form[form].values.origin = 3;

		let data = convertToFormData(state.form[form].values);
		try {
			const response = await apiRequest({
				method: 'post',
				url: state.config.apihost + `/users`,
				data,
				success_message: translate('alert_create_new_user'),
				submit_form: form,
			});

			if (callback) {
				callback(response.data.id);
			}
		} catch (error) {
			if (callback) {
				callback(false);
			}
			console.error('Error in postTicketInviteUserAction:', error);
		}
	};

export const patchTicketInviteUserAction =
	(form, callback = false) =>
	async (dispatch) => {
		const state = store.getState();

		let data = convertToFormData(state.form[form].values);
		data.append('invite', 'yes');

		await apiRequest({
			method: 'patch',
			url: state.config.apihost + `/users/` + state.form[form].values.user_id,
			data,
			submit_form: form,
			handle_error: (error, trigger_event) => {
				if (callback) {
					callback(false);
				}
				trigger_event();
			},
		});
		if (callback) {
			callback(state.form[form].values.user_id);
		}
	};

export const postTicketInviteAction = (form, user) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = {
		conditions_id: state.form[form].values.conditions_id,
		...(state.settings.feature.tickets_reference &&
			typeof state.form[form].values.reference !== 'undefined' && { reference: state.form[form].values.reference }),
		practitioner_id: state.form[form].values.assign_case_to_me == 1 ? state.users.whoami.id : '0',
		user_id: user,
		message: state.form[form].values.message ?? '',
	};

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/tickets/invitation`,
		data: convertToFormData(data),
		success_message: translate('alert_create_invite'),
	});

	if (response.data.practitioner_id == state.users.whoami.id) {
		await dispatch(fetchTicketAction__V2(response.data.id));
		return dispatch(push('/case/' + response.data.token));
	} else {
		return dispatch(push('/waitingroom'));
	}
};

export const patchTicketReferenceAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	return await apiRequest({
		method: 'patch',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id,
		data,
		success_message: translate('alert_patch_reference'),
		submit_form: form,
	});
};

export const patchReferenceAction = (form) => async (dispatch) => {
	const state = store.getState();

	await dispatch(patchTicketReferenceAction(form));
	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	await dispatch(reset(form));

	return eventsService.triggerEvent('tab');
};

export const deletePrescriptionAction = (id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	await apiRequest({
		method: 'delete',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/prescriptions/' + id,
		submit_form: 'ticket_delete_prescription_modal',
		success_message: translate('alert_delete_ticket_prescription_success'),
	});

	return dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
};

export const updateTaskAction =
	(id, status = TICKET.TASKS.STATUS.COMPLETED, text = '') =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);
		var data = new FormData();
		data.append('status', status);
		data.append('text', text);

		await apiRequest({
			method: 'patch',
			url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/tasks/' + id,
			data,
			submit_form: 'ticket_update_prescription_modal',
			success_message:
				status == TICKET.TASKS.STATUS.COMPLETED
					? translate('alert_ticket_prescription_task_validated')
					: status == TICKET.TASKS.STATUS.DENIED
					? translate('alert_ticket_prescription_task_denied')
					: translate('alert_ticket_prescription_task_completed'),
		});

		return dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
	};

const handleFetchTicket = (ticket) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	if (!ticket.scopes.includes(TICKET.SCOPES.MANAGE.VIEW)) {
		const redirect = state.users.whoami.scopes.includes('lists.my_cases') ? '/mycases' : '/dashboard';

		switch (Number(ticket.status)) {
			case TICKET.STATUS.REJECTED:
			case TICKET.STATUS.WAITING:
				Promise.all([
					dispatch(push(redirect)),
					...(state.generic.splash_screen ? [dispatch(handleSplashScreenAction(false))] : []),
					...(state.users.whoami.scopes.includes('practitioner') && ticket.patient.id != state.users.whoami.id
						? [
								dispatch(
									displayModalAction({
										title: translate('ticket_list_claim_modal_title'),
										description: translate('ticket_list_claim_modal_description'),
										button_no: translate('ticket_list_claim_modal_disagree'),
										button_yes: translate('ticket_list_claim_modal_agree'),
										button_yes_id: 'ticket-claim-modal-button',
										action: (props) => dispatch(claimTicketAction(props)),
										props: ticket.id,
									})
								),
						  ]
						: [
								dispatch(
									displayModalAction({
										title: translate('ticket_list_access_denied_modal_title'),
										description: ticket.message
											? translate('ticket_list_access_denied_modal_description_with_message', {
													message: ticket.message,
											  })
											: translate('ticket_list_access_denied_modal_description_without_message'),
										button_no: translate('generic_button_close'),
									})
								),
						  ]),
				]);

				break;
			case TICKET.STATUS.PENDING:
				Promise.all([
					dispatch(push(redirect)),
					...(state.generic.splash_screen ? [dispatch(handleSplashScreenAction(false))] : []),
					...(ticket.patient.id == state.users.whoami.id
						? [
								dispatch(
									displayModalAction({
										title: translate('ticket_list_invite_flow_modal_title'),
										description: translate('ticket_list_invite_flow_modal_description_with_message', {
											message: ticket.message,
										}),
										button_no: translate('ticket_list_invite_flow_modal_disagree'),
										button_yes: translate('ticket_list_invite_flow_modal_agree'),
										action: (props) => dispatch(handleIntiveTicketAction(props)),
										props: ticket.id,
									})
								),
						  ]
						: [
								dispatch(
									displayModalAction({
										title: translate('ticket_list_access_denied_modal_title'),
										description: ticket.message
											? translate('ticket_list_access_denied_modal_description_with_message', {
													message: ticket.message,
											  })
											: translate('ticket_list_access_denied_modal_description_without_message'),
										button_no: translate('generic_button_close'),
									})
								),
						  ]),
				]);
				break;
			case TICKET.STATUS.UNVERIFIED:
				Promise.all([
					dispatch(push(redirect)),
					...(state.generic.splash_screen ? [dispatch(handleSplashScreenAction(false))] : []),
					...(ticket.scopes.includes('tickets.manage.verify')
						? [
								dispatch(
									displayModalAction({
										title: translate('ticket_list_unverified_modal_title'),
										description: translate('ticket_list_unverified_modal_description_with_message', {
											reference: ticket.reference,
										}),
										button_no: translate('ticket_list_unverified_modal_goto'),
										button_yes: translate('ticket_list_unverified_modal_verify'),
										action_no_button: () => {
											dispatch(push('case/' + ticket.token));
										},
										action: (props) => dispatch(verifyTicketModalAction({ ticket_id: ticket.id, ticket_token: ticket.token })),
									})
								),
						  ]
						: [
								dispatch(
									displayModalAction({
										title: translate('ticket_list_access_denied_modal_title'),
										description: ticket.message
											? translate('ticket_list_access_denied_modal_description_with_message', {
													message: ticket.message,
											  })
											: translate('ticket_list_access_denied_modal_description_without_message'),
										button_no: translate('generic_button_close'),
									})
								),
						  ]),
				]);

				break;
			case TICKET.STATUS.CREATED:
				if (ticket.patient.id == state.users.whoami.id && ticket.type === 'ticket' && ticket.questionnaires_blocking == '1') {
					Promise.all([
						dispatch(handleIntiveTicketAction(ticket.id)),
						...(state.generic.splash_screen ? [dispatch(handleSplashScreenAction(false))] : []),
					]);
				} else {
					Promise.all([
						dispatch(push(redirect)),
						...(state.generic.splash_screen ? [dispatch(handleSplashScreenAction(false))] : []),
						dispatch(
							displayModalAction({
								title: translate('ticket_list_access_denied_modal_title'),
								description: ticket.message
									? translate('ticket_list_access_denied_modal_description_with_message', {
											message: ticket.message,
									  })
									: translate('ticket_list_access_denied_modal_description_without_message'),
								button_no: translate('generic_button_close'),
							})
						),
					]);
				}
				break;
			default:
				Promise.all([
					dispatch(push(redirect)),
					...(state.generic.splash_screen ? [dispatch(handleSplashScreenAction(false))] : []),
					dispatch(
						displayModalAction({
							title: translate('ticket_list_access_denied_modal_title'),
							description: ticket.message
								? translate('ticket_list_access_denied_modal_description_with_message', {
										message: ticket.message,
								  })
								: translate('ticket_list_access_denied_modal_description_without_message'),
							button_no: translate('generic_button_close'),
						})
					),
				]);
				break;
		}
	} else {
		Promise.all([dispatch(push('/case/' + ticket.token)), ...(state.generic.splash_screen ? [dispatch(handleSplashScreenAction(false))] : [])]);
	}
};

export const postIncreaseAnsweringTimeAction = () => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	await apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/increaseansweringtime',
		success_message: translate('alert_post_increase_time'),
	});

	await dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

	return eventsService.triggerEvent('tab');
};

export const setTicketUnreadAction = (unreads) => (dispatch) => {
	dispatch({
		type: SET_UNREAD_TICKET_DATA,
		payload: unreads,
	});
};

export const clearTicketsListAction = () => (dispatch) => {
	dispatch({
		type: CLEAR_TICKETS_LIST,
	});
};

export const clearTicketDataAction = () => (dispatch) => {
	dispatch({
		type: CLEAR_TICKET_DATA,
	});
};

/**
 * Calls api endpoint to upload a file to a Ticket
 * @param {formData} data form data to use as body params for the request, contains the file
 * @param {int} ticket_id
 * @returns
 */
export const postFileChatAction = (data, ticket_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	return apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + ticket_id + '/files',
		data,
		submit_form: 'ticket_upload_file_chat',
		success_message: translate('alert_post_uploadfile'),
	});
};

/**
 * Calls api endpoint to upload a file to a Ticket
 * @param {formData} data form data to use as body params for the request, contains the file
 * @param {int} ticket_id
 * @returns
 */
export const getFileAction = (link) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	return apiRequest({
		method: 'get',
		url: state.config.apihost + link,
		submit_form: 'ticket_upload_file_chat',
		success_message: translate('alert_post_uploadfile'),
	});
};

/**
 * Calls api endpoint to upload a file to a Ticket
 * @param {formData} data form data to use as body params for the request, contains the file
 * @param {int} ticket_id
 * @returns
 */
export const getQuestionnaireFileAction = (uuid, ticket_id, questionnaire_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	return apiRequest({
		method: 'get',
		url: state.config.apihost + `/tickets/` + ticket_id + `/questionnaires/` + questionnaire_id + `/files/` + uuid,
		submit_form: 'ticket_upload_file_chat',
		success_message: translate('alert_post_uploadfile'),
	});
};

/**
 * Calls api endpoint to upload a file to a Ticket
 * @param {formData} data form data to use as body params for the request, contains the file
 * @returns
 */
export const postTagTicketAction = (tag_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	return apiRequest({
		method: 'post',
		url: state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/tags/' + tag_id,
		success_message: translate('alert_post_ticket_tag'),
	});
};

export const deleteTagTicketAction = (tag_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	await apiRequest({
		method: 'delete',
		url: state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/tags/` + tag_id,
		submit_form: 'ticket_patient_delete',
		success_message: translate('alert_delete_ticket_tag'),
	});
};
/**
 * Calls api endpoint to upload tag files to a Ticket
 * @param {int} ticket_id
 * @returns
 */
export const getTicketTagFileAction = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/taggedfiles`,
	});
	return dispatch({
		type: FETCH_TAGGED_FILES,
		payload: response.data,
	});
};

/**
 * Calls api endpoint to upload tag files to a Ticket
 * @returns
 */
export const getTaggedLibraryFilesAction = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/tickets/taggedfiles`,
	});
	return dispatch({
		type: FETCH_TAGGED_FILES,
		payload: response.data,
	});
};
/**
 * Calls api endpoint to upload tag files to a Ticket
 * @returns
 */
export const getTicketsFilesAction = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/tickets/files`,
	});
	return dispatch({
		type: FETCH_MY_FILES,
		payload: response.data,
	});
};
const ticketsActions = {
	getFileAction,
};

export default ticketsActions;
