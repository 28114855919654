import WIDGETS from './widgets';

export default {
	name: 'statistic',
	spacing: 2,
	widgets: [
		{
			content: WIDGETS.STATISTIC_OVERVIEW,
			breakpoints: { xs: 12 },
			lazy_loading: 0,
		},
		{
			content: {
				spacing: 0,
				widgets: [
					{
						content: WIDGETS.STATISTIC_CONTACTS,
						breakpoints: { xs: 12, sm: 3 },
						lazy_loading: 0,
					},
          {
						content: WIDGETS.STATISTIC_CONTACTS_INTERVAL,
						breakpoints: { xs: 12, sm: 9 },
						lazy_loading: 0,
					},
					{
						content: WIDGETS.STATISTIC_CONTACTS_TYPE,
						breakpoints: { xs: 12, sm: 6 },
						lazy_loading: 0,
					},
					{
						content: WIDGETS.STATISTIC_CONTACTS_GENDER,
						breakpoints: { xs: 12, sm: 6 },
						lazy_loading: 0,
					},
					{
						content: WIDGETS.STATISTIC_CONTACTS_CATEGORY,
						breakpoints: { xs: 12, sm: 6 },
						lazy_loading: 0,
					},
					{
						content: WIDGETS.STATISTIC_CONTACTS_CATEGORY_GROUP,
						breakpoints: { xs: 12, sm: 6 },
						lazy_loading: 0,
					},
					{
						content: WIDGETS.STATISTIC_CONTACTS_TAG_GROUPS,
						breakpoints: { xs: 12, sm: 6 },
						lazy_loading: 0,
					},
					{
						content: WIDGETS.STATISTIC_TICKETS_REFERRAL,
						breakpoints: { xs: 12, sm: 6 },
						lazy_loading: 0,
					},
				],
			},
			breakpoints: { xs: 12, sm: 12 },
			lazy_loading: 0,
			props: {
				rowSpacing: 2,
			},
		},
	],
};
