import React from 'react';
import { Autocomplete } from '@mui/material';
import { TextField, FormHelperText } from '@mui/material';

function RenderAutocomplete({ input, meta: { invalid, touched, error, active }, ...custom }) {
	return (
		<div>
			<Autocomplete
				className={custom.inputdata.validate && touched && invalid ? 'generic-form-autocomplete-error' : ''}
				multiple={custom.inputdata.multiple}
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				options={custom.inputdata.options}
				groupBy={custom.inputdata.groupby}
				getOptionLabel={(option) => (custom.inputdata.getOptionLabel ? custom.inputdata.getOptionLabel(option) : option.label)}
				blurOnSelect={true}
				defaultValue={custom.inputdata.options.find((option) => option.value === custom.inputdata.initialvalue)}
				{...(custom.inputdata.onInputChange && {
					onInputChange: (event, newInputValue, reason) => {
						custom.inputdata.onInputChange(event, newInputValue, reason);
					},
				})}
				isOptionEqualToValue={(option, value) => {
					return option.value === value.value;
				}}
				clearOnBlur={false}
				freeSolo={custom.inputdata?.freeSolo ?? false}
				onChange={(event, option) => {
					input.onChange(option?.value ? option.value : '');
					if (custom.inputdata.onSelect) {
						custom.inputdata.onSelect(option);
					}
				}}
				disableCloseOnSelect={Boolean(custom.inputdata.readonly)}
				filterSelectedOptions={true}
				renderInput={(params) => {
					return (
						<TextField
							label={custom.inputdata.label}
							onChange={(event, value) => {
								input.onChange(value ? value : event.target.value);
								if (typeof custom.inputdata.onChange !== 'undefined') {
									custom.inputdata.onChange(value, event.target.value);
								}
							}}
							fullWidth
							variant="outlined"
							value={params.inputProps.value}
							error={touched && invalid && !active}
							helperText={touched && !active && error}
							{...custom}
							{...params}
							disabled={Boolean(custom.inputdata.readonly)}
						/>
					);
				}}
			/>
			<FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"> {custom.inputdata.helperText}</FormHelperText>
		</div>
	);
}

export default RenderAutocomplete;
