import chat from './chat';
import conditions from './conditions';
import generic from './generic';
import login from './login';
import questionnaires from './questionnaires';
import tickets from './tickets';
import users from './users';
import videoconference from './videoconference';
import welcome from './welcome';
import mycalendar from './mycalendar';
import image_list_display from './image_list_display';
import notifications from './notifications';
import dashboard from './dashboard';
import table from './table';
import activities from './activities';
import libraries from './libraries';
import meetings from './meetings'
import precall from './precall'
import error_boundary from './error_boundary';
import snackbar from './snackbar';
import tui_editor from './tui_editor';
import poa from './poa';
import block_ui from './block_ui';
import react_grid_layout from './react_grid_layout';
import react_resizable from './react_resizable';
import react_big from './react_big';
import toast_ui from './toast_ui';

const classes = (theme) => {
    return {
        ...chat(theme),
        ...conditions(theme),
        ...generic(theme),
        ...login(theme),
        ...questionnaires(theme),
        ...tickets(theme),
        ...users(theme),
        ...videoconference(theme),
        ...welcome(theme),
        ...mycalendar(theme),
        ...image_list_display(theme),
        ...notifications(theme),
        ...dashboard(theme),
        ...table(theme),
        ...activities(theme),
        ...libraries(theme),
        ...meetings(theme),
        ...precall(theme),
        ...error_boundary(theme),
        ...snackbar(theme),
        ...tui_editor(theme),
        ...poa(theme),
        ...block_ui(theme),
        ...react_grid_layout(theme),
        ...react_resizable(theme),
        // ...react_big(theme),
        ...toast_ui(theme),
    };
};

export default classes;
