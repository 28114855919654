import axios from 'axios';
import {
	LOGIN,
	LOGIN_BANKID,
	BANKID_PROGRESS,
	LOGIN_FREJAID,
	FREJAID_PROGRESS,
	REFRESH_TOKEN,
	SELECT_CLIENT,
	BANKID_QRCODE,
	CLEAR_REDUCER,
	LOGIN_SELF_REGISTRATION,
	REFRESH_TOKEN_DATE,
	RESET_MFA_LOGIN,
} from './types';
import { store } from '../../../store.js';
import { whoamiAction, cleanWhoamiAction, loadWhoami } from '../../Users/actions/usersActions';
import { addDelayAction, convertToFormData, handleBlockingUIAction, handleSplashScreenAction } from '../../Generic/actions/genericActions';
import eventsService from '../../../helpers/Events';
import { getTranslate } from 'react-localize-redux';
import { setPromptDialogIsDirty } from '../../Generic/actions/promptActions';
import { isMobileSize } from '../../../helpers/functions/functions';
import { getActiveLanguage } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';
import { dateToString } from '../../../helpers/functions/functions';
import { handleError, apiRequest } from '../../../helpers/functions/request';
import { handleRedirect } from '../../../actions/genericActions';
import moment from 'moment';
import { disconnectAction } from '../../Chat/actions/websocketsActions';
import { removeSettingsAction } from '../../../actions/settingsActions.js';
import { destroy } from 'redux-form';

let checkBankIdProgressTimer = null;
let checkBankIdProgressTries = 0;

let checkFrejaIdProgressTimer = null;
let checkFrejaIdProgressTries = 0;

export const loginAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	dispatch(handleBlockingUIAction(true, 'block_ui_login'));

	var data = new FormData();
	data.append('grant_type', 'password');
	data.append('username', state.form[form].values.login_email ? state.form[form].values.login_email : state.form[form].values.email);
	data.append('password', state.form[form].values.login_password ? state.form[form].values.login_password : state.form[form].values.password);
	data.append('language', state.form[form].values.language);
	data.append('client_id', state.login.client.id);

	if (typeof state.router.location?.query?.token !== 'undefined') {
		data.append('token', state.router.location?.query?.token);
	}

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/login`,
		data,
		alert: 'alert_login',
		handle_error: (error, trigger_event) => {
			if (typeof error.response.data.context.redirect !== 'undefined' && error.response.data.context.redirect !== null) {
				window.location.replace(error.response.data.context.redirect);
			} else {
				dispatch(handleBlockingUIAction(false));
				trigger_event();
			}
		},
	});

	if (response.data?.mfa?.require && !response.data?.mfa?.verified) {
		await dispatch({
			type: LOGIN,
			payload: {
				login: false,
				confirmed: response.data.confirmed,
				email_confirm: state.form[form].values.login_email ? state.form[form].values.login_email : state.form[form].values.email,
				mfa: response.data.mfa,
			},
		});

		return dispatch(handleBlockingUIAction(false));
	} else if (!response.data.confirmed) {
		await dispatch({
			type: LOGIN,
			payload: {
				login: false,
				confirmed: response.data.confirmed,
				email_confirm: state.form[form].values.login_email ? state.form[form].values.login_email : state.form[form].values.email,
			},
		});
		dispatch(handleBlockingUIAction(false));
		return dispatch(push('/register/login'));
	}

	dispatch(handleBlockingUIAction(false));

	dispatch(handleSplashScreenAction(true));

	if (state.login.platform === 'backoffice') {
		if (response.data.scopes.includes('admin.access')) {
			setTimeout(() => {
				return dispatch(setAdminTokenAction(response.data));
			}, 2000);
		} else {
			dispatch(logoutAction(false, false));
			return dispatch(handleSplashScreenAction(false));
		}
	} else {
		await dispatch(loadWhoami(true));

		await dispatch(handleRedirect());

		return dispatch(handleSplashScreenAction(false));
	}

	// TODO: check error message from api;
	// if (err.request && err.request.status === 401) {
	// 	eventsService.triggerEvent('alert_login', { type: 'error', message: JSON.parse(err.request.responseText).error });
	// } else if (err.request.status === 422) {
	// 	if (err.response.data.context.redirect !== null) {
	// 		window.location.replace(err.response.data.context.redirect);
	// 	} else {
	// 		handleError(err, 'alert_login');
	// 	}
	// } else if (err.name && err.name === 'login_backoffice_no_access') {
	// 	eventsService.triggerEvent('alert_login', { type: 'error', message: err.message });
	// } else {
	// 	eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_login_no_response') });
	// }
};

export const refreshTokenAction =
	(callback = false) =>
	async (dispatch) => {
		const state = store.getState();

		try {
			const response = await apiRequest({
				method: 'post',
				url: state.config.apihost + `/login/refresh`,
				headers: { 'Content-Type': 'multipart/form-data' },
			});

			if (callback) {
				callback(false);
			}

			return dispatch({
				type: REFRESH_TOKEN,
				payload: {
					login: true,
					token_expires_at: response.data.token_expires_at,
				},
			});
		} catch (error) {
			if (callback) {
				callback(false);
			}

			return dispatch(logoutAction(true));
		}
	};

export const checkBankIdProgress =
	(callback = false) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		if (!state.login.session_id) {
			if (callback) {
				callback({ status: 'failed', QRImage: null, autoStartUrl: null, session_id: null });
			}
			return;
		}
    
		let qrCode = document.getElementById('bankid_qrcode');

		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/auth/logins/bankid/` + state.login.session_id,
			alert: 'alert_login',
			handle_error: (error, trigger_event) => {
				clearInterval(checkBankIdProgressTimer);
				dispatch({
					type: BANKID_PROGRESS,
					payload: {
						bankid_progress: false,
						session_id: '',
					},
				});
				if (callback) {
					callback({ status: 'failed', QRImage: null, autoStartUrl: null, session_id: null });
				}
				if (qrCode !== null) {
					qrCode.classList.add('login-bankid-qrcode-disabled');
				}

				eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_bankid_session_expired') });
			},
		});

		if (response.data.confirmed === false) {
			clearInterval(checkBankIdProgressTimer);
			dispatch(push('/login'));
			if (callback) {
				callback(response.data);
			}
			return dispatch({
				type: LOGIN_BANKID,
				payload: {
					login: false,

					confirmed: response.data.confirmed,
					bankid_progress: false,
				},
			});
		} else {
			if (typeof response.data.access_token !== 'undefined' && !store.getState().login.login) {
				clearInterval(checkBankIdProgressTimer);

				dispatch(handleSplashScreenAction(true));

				if (state.login.platform === 'backoffice') {
					if (response.data.scopes.includes('admin.access')) {
						setTimeout(() => {
							dispatch({
								type: BANKID_PROGRESS,
								payload: {
									bankid_progress: false,
									session_id: '',
								},
							});

							return dispatch(setAdminTokenAction(response.data));
						}, 2000);
					} else {
						dispatch(logoutAction(false, false));
						return dispatch(handleSplashScreenAction(false));
					}
				} else {
					await dispatch(loadWhoami(true));

					await dispatch(handleRedirect());

					dispatch({
						type: BANKID_PROGRESS,
						payload: {
							bankid_progress: false,
							session_id: '',
						},
					});

					return dispatch(handleSplashScreenAction(false));
				}
			} else if (checkBankIdProgressTries >= 60 || response.data.status === 'failed') {
				if (callback) {
					await callback(response.data);
				}
				clearInterval(checkBankIdProgressTimer);
				checkBankIdProgressTries = 0;
				dispatch({
					type: BANKID_PROGRESS,
					payload: {
						bankid_progress: false,
						session_id: '',
					},
				});

				if (qrCode !== null) {
					qrCode.classList.add('login-bankid-qrcode-disabled');
				}
			} else {
				if (callback) {
					await callback(response.data);
				}
				checkBankIdProgressTries++;
			}
		}
	};

export const loginBankIDAction = (form, selectedLanguage) => async (dispatch) => {
	const state = store.getState();

	var data = new FormData();

	data.append('grant_type', 'bankid');
	data.append('ssn', state.form[form].values.bankid_ssn);
	data.append('language', selectedLanguage);
	data.append('client_id', state.login.client.id);

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/login`,
		data,
		headers: { 'Content-Type': 'multipart/form-data' },
		alert: 'alert_login',
		handle_error: (error, trigger_event) => {
			if (typeof error.response.data.context.redirect !== 'undefined' && error.response.data.context.redirect !== null) {
				window.location.replace(error.response.data.context.redirect);
			} else {
				trigger_event();
			}
		},
	});

	dispatch({
		type: BANKID_PROGRESS,
		payload: {
			bankid_progress: true,
			session_id: response.data.sessionId,
		},
	});

	let qrCode = document.getElementById('bankid_qrcode');
	if (qrCode !== null) {
		qrCode.classList.remove('login-bankid-qrcode-disabled');
	}

	clearInterval(checkBankIdProgressTimer);
	checkBankIdProgressTimer = null;

	checkBankIdProgressTimer = setInterval(() => dispatch(checkBankIdProgress()), 1000);

	if (isMobileSize()) {
		window.location.replace('bankid://' + response.data.autoStartToken);
	}

	return response;

	// TODO: check error message from api;
	// if (err.request.status === 422) {
	// 	if (err.response.data.context.redirect !== null) {
	// 		window.location.replace(err.response.data.context.redirect);
	// 	} else {
	// 		handleError(err, 'alert_login');
	// 	}
	// } else {
	// 	eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_login_no_response') });
	// 	dispatch({
	// 		type: LOGIN,
	// 		payload: {
	// 			login: false,
	// 		},
	// 	});
	// }
};

export const loginAutostartBankIDAction = (selectedLanguage) => async (dispatch) => {
	const state = store.getState();

	var data = new FormData();

	data.append('grant_type', 'bankid');
	data.append('language', selectedLanguage);
	data.append('client_id', state.login.client.id);

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/login`,
		data,
		headers: { 'Content-Type': 'multipart/form-data' },
		alert: 'alert_login',
		handle_error: (error, trigger_event) => {
			if (typeof error.response.data.context.redirect !== 'undefined' && error.response.data.context.redirect !== null) {
				window.location.replace(error.response.data.context.redirect);
			} else {
				trigger_event();
			}
		},
	});

	dispatch({
		type: BANKID_PROGRESS,
		payload: {
			bankid_progress: true,
			session_id: response.data.sessionId,
		},
	});

	clearInterval(checkBankIdProgressTimer);
	checkBankIdProgressTimer = null;

	checkBankIdProgressTimer = setInterval(() => dispatch(checkBankIdProgress()), 1000);

	if (isMobileSize()) {
		window.location.replace('bankid://' + response.data.autoStartToken);
	}

	return response;
};

export const createSessionAction =
	({ client_id, language, callback = false }) =>
	async (dispatch) => {
		const state = store.getState();
		var data = new FormData();

		data.append('grant_type', 'bankid');
		data.append('language', language);
		data.append('client_id', client_id);

		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + `/login`,
			data,
			headers: { 'Content-Type': 'multipart/form-data' },
			alert: 'alert_login',
			handle_error: (error, trigger_event) => {
				if (typeof error.response.data.context.redirect !== 'undefined' && error.response.data.context.redirect !== null) {
					window.location.replace(error.response.data.context.redirect);
				} else {
					trigger_event();
				}
			},
		});

		await dispatch({
			type: BANKID_PROGRESS,
			payload: {
				bankid_progress: true,
				session_id: response.data.sessionId,
			},
		});

		if (callback) {
			await callback(response.data);
		}
	};

export const bankIDQRGeneratorAction = (selectedLanguage) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var formData = new FormData();

	formData.append('grant_type', 'bankid');
	formData.append('language', selectedLanguage);
	formData.append('client_id', state.login.client.id);

	axios
		.post(state.config.apihost + `/login`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then((result) => {
			dispatch({
				type: BANKID_QRCODE,
				payload: {
					session_id: result.data.sessionId,
					autostarttoken: result.data.autoStartToken,
				},
			});

			let qrCode = document.getElementById('bankid_qrcode');
			if (qrCode !== null) {
				qrCode.classList.remove('login-bankid-qrcode-disabled');
			}

			clearInterval(checkBankIdProgressTimer);
			checkBankIdProgressTimer = null;

			checkBankIdProgressTimer = setInterval(() => dispatch(checkBankIdProgress()), 1000);
		})
		.catch((err) => {
			eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_login_no_response') });
			dispatch({
				type: LOGIN,
				payload: {
					login: false,
				},
			});
		});
};

export const loginFrejaIDAction = (form, selectedLanguage) => async (dispatch) => {
	const state = store.getState();

	var data = new FormData();

	data.append('grant_type', 'frejaid');
	data.append('ssn', state.form[form].values.frejaid_ssn);
	data.append('language', selectedLanguage);
	data.append('client_id', state.login.client.id);

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/login`,
		data,
		headers: { 'Content-Type': 'multipart/form-data' },
		alert: 'alert_login',
		handle_error: (error, trigger_event) => {
			if (typeof error.response.data.context.redirect !== 'undefined' && error.response.data.context.redirect !== null) {
				window.location.replace(error.response.data.context.redirect);
			} else {
				trigger_event();
			}
		},
	});

	dispatch({
		type: FREJAID_PROGRESS,
		payload: {
			frejaid_progress: true,
			session_id: response.data.sessionId,
		},
	});

	clearInterval(checkFrejaIdProgressTimer);
	checkFrejaIdProgressTimer = null;

	checkFrejaIdProgressTimer = setInterval(() => dispatch(checkFrejaIDProgress()), 1000);

	// if (isMobileSize()) {
	// 	window.location.replace('bankid://' + response.data.autoStartToken);
	// }

	return response;
};

export const checkFrejaIDProgress = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + '/login/' + state.login.session_id + '?grant_type=frejaid',
		alert: 'alert_login',
		handle_error: (error, trigger_event) => {
			clearInterval(checkFrejaIdProgressTimer);
			dispatch({
				type: FREJAID_PROGRESS,
				payload: {
					frejaid_progress: false,
					session_id: '',
				},
			});

			trigger_event();
		},
	});

	if (response.data.confirmed === false) {
		clearInterval(checkFrejaIdProgressTimer);
		dispatch(push('/login'));
		return dispatch({
			type: LOGIN_FREJAID,
			payload: {
				login: false,

				confirmed: response.data.confirmed,
				frejaid_progress: false,
			},
		});
	} else {
		if (typeof response.data.access_token !== 'undefined' && !store.getState().login.login) {
			clearInterval(checkFrejaIdProgressTimer);

			dispatch(handleSplashScreenAction(true));

			if (state.login.platform === 'backoffice') {
				if (response.data.scopes.includes('admin.access')) {
					setTimeout(() => {
						dispatch({
							type: FREJAID_PROGRESS,
							payload: {
								frejaid_progress: false,
								session_id: '',
							},
						});

						return dispatch(setAdminTokenAction(response.data));
					}, 2000);
				} else {
					dispatch(logoutAction(false, false));
					return dispatch(handleSplashScreenAction(false));
				}
			} else {
				await dispatch(loadWhoami(true));

				await dispatch(handleRedirect());

				dispatch({
					type: FREJAID_PROGRESS,
					payload: {
						frejaid_progress: false,
						session_id: '',
					},
				});

				return dispatch(handleSplashScreenAction(false));
			}
		} else if (checkFrejaIdProgressTries >= 60) {
			clearInterval(checkFrejaIdProgressTimer);
			checkFrejaIdProgressTries = 0;
			dispatch({
				type: FREJAID_PROGRESS,
				payload: {
					frejaid_progress: false,
					session_id: '',
				},
			});
		} else {
			checkFrejaIdProgressTries++;
		}
	}
};

export const resetFrejaIDProgress = () => (dispatch) => {
	dispatch({
		type: FREJAID_PROGRESS,
		payload: {
			frejaid_progress: false,
			session_id: '',
		},
	});
};

export const loginNetIDAction = (values) => (dispatch) => {};

export const checkNetIdProgress = () => (dispatch) => {};

export const logoutAction =
	(keepClient = false, redirect = true, message = '') =>
	(dispatch) => {
		const state = store.getState();
		if (state.prompt.is_dirty) {
			dispatch(
				setPromptDialogIsDirty({
					is_dirty: false,
				})
			);
		}

		axios.post(state.config.apihost + `/logout`).catch((err) => {
			eventsService.triggerEvent('alert_login', { type: 'error', message: err });
		});

		Promise.resolve(
			dispatch({
				type: LOGIN,
				payload: {
					login: false,
					self_registration: false,
					confirmed: true,
					message: message,
					...(!keepClient
						? { client: null }
						: { client: state.settings.clients.find((client) => client.id === state.users.whoami.current_client) }),
				},
			})
		)
			.then(() => dispatch(disconnectAction()))
			.then(() => {
				Object.keys(state.form).forEach((form) => {
					dispatch(destroy(form));
				});
			})
			.then(() => (redirect ? dispatch(push('/login')) : null))
			.then(() => dispatch({ type: CLEAR_REDUCER }))
			.then(() => dispatch(cleanWhoamiAction()));
	};

export const prepareStoreLogoutAction = () => (dispatch) => {
	const state = store.getState();

	if (state.prompt.is_dirty) {
		dispatch(
			setPromptDialogIsDirty({
				is_dirty: false,
			})
		);
	}

	dispatch({
		type: LOGIN,
		payload: {
			login: false,
			client: null,
			platform: 'backoffice',
			confirmed: true,
		},
	});
	dispatch(cleanWhoamiAction());
};

export const setAdminTokenAction = (data) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var form = new FormData();

	form.append('access_token', data.access_token);
	form.append('refresh_token', data.refresh_token);

	axios
		.post(state.config.adminhost + `/login`, form, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then((res) => {
			dispatch(prepareStoreLogoutAction());
		})
		.then(() => {
			window.location.href = state.config.adminhost;
		})
		.catch((err) => {
			eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_admin_no_response') });
		});
};

export const handleSelectClientAction =
	(client, platform, method = null) =>
	(dispatch) => {
		const state = store.getState();

		if (client !== null && client.page_title) {
			document.title = client.page_title;
		} else {
			document.title = state.settings.site.page_title;
		}

		dispatch({
			type: SELECT_CLIENT,
			payload: {
				client,
				platform,
			},
		});

		var language = getActiveLanguage(state.localize);

		if (platform == 'backoffice' && method == 'bankID' && !isMobileSize()) {
			dispatch(addDelayAction(bankIDQRGeneratorAction(language.code)));
		}
	};
export const createNewAccount =
	(form, verify, callback = false) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		if (typeof state.form[form].values.birthdate !== 'undefined') {
			state.form[form].values.birthdate = dateToString(
				new Date(
					state.form[form].values.birthdate.toLocaleString('en-US', {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						hour12: true,
					})
				)
			);
		}

		state.form[form].values.consented = state.form[form].values.consented ? '1' : '0';

		const { insurance_company, insurance_number, insurance_valid_date, ...formData } = state.form[form].values;
		const data = convertToFormData(formData);

		if (
			typeof state.settings.feature !== 'undefined' &&
			state.settings.feature.insurances &&
			state.settings.users.registration_fields.find((elem) => elem.field == 'insurance') &&
			state.settings.users.registration_fields.find((elem) => elem.field == 'insurance').display
		) {
			const insuranceDate = moment(state.form['create_new_insurance'].values.insurance_valid_date).format('YYYY-MM-DD');
			const insurance = {
				name: state.form['create_new_insurance'].values.insurance_company,
				number: state.form['create_new_insurance'].values.insurance_number,
				expired_at: insuranceDate,
			};
			data.append('insurance[name]', insurance.name);
			data.append('insurance[number]', insurance.number);
			data.append('insurance[expired_at]', insurance.expired_at);
		}

		if (verify) {
			data.append('ssn', state.form['create_new_account_search'].values.ssn);
		}

		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + `/users/register`,
			data,
			success_message: translate('alert_success_new_user_created'),
			alert: 'alert_login',
			submit_form: form,
		});

		await dispatch({
			type: LOGIN_SELF_REGISTRATION,
			payload: {
				self_registration: true,
				email_confirm: response.data.email,
			},
		});

		if (response.status === 201) {
			callback(true);
		}
		return dispatch(loginAction(form));
	};

export const sendVerifyTokenAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var body = new FormData();
	body.append('token', state.form[form].values.verify_token);
	body.append('medium', 'email');
	body.append('value', state.form[form].values.email);

	axios
		.post(state.config.apihost + `/users/contact/verify`, body)
		.then((res) => {
			dispatch(
				addDelayAction(reset(form), () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', {
						type: 'success',
						message: translate('alert_success_verify'),
					})
				)
			);
			dispatch({
				type: LOGIN,
				payload: {
					login: true,
					token: res.data.access_token,
					refresh_token: res.data.refresh_token,
					expires_in: res.data.expires_in,
					confirmed: res.data.confirmed,
				},
			});

			dispatch(whoamiAction(true, true));
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			dispatch(
				addDelayAction(null, () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', {
						type: 'error',
						message: translate(err.response.data.message),
					})
				)
			);
		});
};

export const resetBankIDProgress = () => (dispatch) => {
	dispatch({
		type: BANKID_PROGRESS,
		payload: {
			bankid_progress: false,
			session_id: '',
		},
	});
};

export const refreshTokenExpireDate =
	(get_translations = false) =>
	async (dispatch) => {
		const state = store.getState();
		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/auth/whoami`,
			handle_error: (error, trigger_event) => {
				if (state.login.login) {
					return dispatch(logoutAction(true));
				} else {
					trigger_event();
				}
			},
		});

		return dispatch({
			type: REFRESH_TOKEN_DATE,
			payload: {
				token_expires_at: response.data.token_expires_at,
			},
		});
	};

// TODO: Implement this to chain actions on login actions and others

// export const loginTokenChaining = (token, client) => async (dispatch) => {
// 	const state = store.getState();

// 	await Promise.all([
// 		dispatch(handleSplashScreenAction(true)),
// 		dispatch(loginTokenAction(token, client)), // <-- async dispatch chaining in action
// 		dispatch(handleSelectClientAction(state.settings.clients.find((clinic) => clinic.id === client)), 'frontoffice'),
// 		dispatch(handleSplashScreenAction(false)),
// 	]);

// 	return dispatch(whoamiAction(true));
// };

export const loginAutomaticAction = () => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var data = new FormData();
	data.append('grant_type', 'safecheckin');
	data.append('jwt', state.settings.params.jwt);
	if (state.settings.params.user_token) {
		data.append('user_token', state.settings.params.user_token);
	}
	data.append('client_id', state.settings.params.clients_id);

	// data.append('username', state.settings.params.jwt);
	// data.append('password', 'pMUdm73TXoa6mB'); // ac12
	// data.append('password', 'B4jq89WefkeW94bR'); // local

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/login`,
		data,
		alert: 'alert_login',
		handle_error: (error, trigger_event) => {
			if (typeof error.response.data.context.redirect !== 'undefined' && error.response.data.context.redirect !== null) {
				window.location.replace(error.response.data.context.redirect);
			} else {
				trigger_event();
			}
		},
	});

	if (!response.data.confirmed) {
		dispatch(push('/login'));

		return dispatch({
			type: LOGIN,
			payload: {
				login: false,
				confirmed: response.data.confirmed,
				email_confirm: response.data.email,
			},
		});
	}

	await dispatch(loadWhoami(true));

	await dispatch(handleRedirect());

	await dispatch(removeSettingsAction(['jwt', 'clients_id']));

	return dispatch(handleSplashScreenAction(false));
};

export const resetLoginMfa = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({ method: 'post', url: state.config.apihost + `/logout` });

	return dispatch({
		type: RESET_MFA_LOGIN,
	});
};

export const loginVerifyTOTPAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var data = new FormData();
	data.append('totp', state.form[form].values.totp);

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/login/totp`,
		data,
		alert: 'alert_login',
		handle_error: (error, trigger_event) => {
			if (typeof error.response.data.context.redirect !== 'undefined' && error.response.data.context.redirect !== null) {
				window.location.replace(error.response.data.context.redirect);
			} else {
				dispatch(reset(form));
				trigger_event();
			}
		},
	});

	if (response.data?.mfa?.require && !response.data?.mfa?.verified) {
		await dispatch({
			type: LOGIN,
			payload: {
				login: false,
				confirmed: response.data.confirmed,
				email_confirm: state.form[form].values.login_email ? state.form[form].values.login_email : state.form[form].values.email,
				mfa: response.data.mfa,
			},
		});

		return dispatch(reset(form));
	} else if (!response.data.confirmed) {
		await dispatch({
			type: LOGIN,
			payload: {
				login: false,
				confirmed: response.data.confirmed,
				email_confirm: state.form[form].values.login_email ? state.form[form].values.login_email : state.form[form].values.email,
			},
		});
		return dispatch(push('/register/login'));
	}

	dispatch(handleSplashScreenAction(true));

	if (state.login.platform === 'backoffice') {
		if (response.data.scopes.includes('admin.access')) {
			setTimeout(() => {
				return dispatch(setAdminTokenAction(response.data));
			}, 2000);
		} else {
			dispatch(logoutAction(false, false));
			return dispatch(handleSplashScreenAction(false));
		}
	} else {
		await dispatch(loadWhoami(true));

		await dispatch(handleRedirect());

		await dispatch(reset(form));

		return dispatch(handleSplashScreenAction(false));
	}
};
