import DASHBOARD from './dashboard';
import NHS_PATIENT_DASHBOARD from './nhs_patient_dashboard';
import AD_PATIENT_DASHBOARD from './ad_patient_dashboard';
import NAVIGATION from './navigation';
import NAVIGATION_WEIGHT_UNITS from './navigation_weight_units';
import NAVIGATION_MAIN_NHS from './navigation_main_nhs';
import CHAT from './chat';
import WEIGHTDETAILS from './weight_details';
import FOODDETAILS from './food_details';
import MOODDETAILS from './mood_details';
import EXERCISEDETAILS from './exercise_details';
import DAILYREPORTDETAILS from './daily_report_details';
import SMARTGOALSWEIGHT from './smart_goals_weigh';
import FOODQUESTIONNAIRE from './food_questionnaire';
import COMMUNITY from './community_details';
import ACTIVITY_SUMMARY from './activity_summary';
import NHS_PRACTITIONER_TICKET from './nhs_practitioner_ticket';
import TICKET from './ticket';
import FOODLOGS from './food_logs';
import MOODLOGS from './mood_logs';
import EXERCISELOGS from './exercise_logs';
import FILESTAGS from './file_tags';
import LIBRARYFILES from './library_files';
import MY_FILES_DASHBOARD from './my_files';
import EVENT_CANCEL from './event_cancel';
import ANALYTIC_TICKETS_LOGS from './analytic_tickets_logs';
import ANALYTIC_TICKET_LOGS from './analytic_ticket_logs';
import INVITE from './invite';
import INVITE_SINGLE_FORM from './invite_single_form';
import PRECALL_MEETING from './precall_meeting';
import SURVEY from './survey';
import STATISTIC from './statistic';
import MU_NAVIGATION from './mu_navigation';

// Inside my proptypes.js file
import PropTypes, { oneOfType, arrayOf, shape, number, string } from 'prop-types';

export const widget_structure = {
	content: string.isRequired,
	breakpoints: shape({
		xs: number,
		sm: number,
		md: number,
		xl: number,
	}).isRequired,
	lazy_loading: number /** miliseconds */,
};

export const catalogue_structure = {
	name: string.isRequired /** Options: name of catalogue */,
	sx: oneOfType([arrayOf(oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])), PropTypes.func, PropTypes.object]) /** MUI sx prop */,
	spacing: number /** Theme spacing options */,
	rowSpacing: number /** Theme rowSpacing options */,
	columnSpacing: number /** Theme columnSpacing options */,
	direction: string /** Options: row, row-reverse, column, column-reverse */,
	justifyContent: string /** Options: flex-start, center, flex-end, space-between, space-around, space-evenly */,
	alignItems: string /** Options: flex-start, center, flex-end, stretch, baseline */,
	widgets: arrayOf(shape(widget_structure)).isRequired,
};

export default {
	DASHBOARD,
	NAVIGATION,
	NAVIGATION_WEIGHT_UNITS,
  NAVIGATION_MAIN_NHS,
	CHAT,
	WEIGHTDETAILS,
	FOODDETAILS,
	MOODDETAILS,
	EXERCISEDETAILS,
	DAILYREPORTDETAILS,
	SMARTGOALSWEIGHT,
	FOODQUESTIONNAIRE,
	NHS_PATIENT_DASHBOARD,
	COMMUNITY,
	ACTIVITY_SUMMARY,
	TICKET,
	NHS_PRACTITIONER_TICKET,
  AD_PATIENT_DASHBOARD,
	FOODLOGS,
	MOODLOGS,
	EXERCISELOGS,
	FILESTAGS,
	LIBRARYFILES,
	MY_FILES_DASHBOARD,
	PRECALL_MEETING,
	INVITE,
	INVITE_SINGLE_FORM,
	ANALYTIC_TICKETS_LOGS,
	ANALYTIC_TICKET_LOGS,
	SURVEY,
	EVENT_CANCEL,
	STATISTIC,
	MU_NAVIGATION,
};
