import WIDGETS from './widgets';

export default {
	name: 'ad_patient_dashboard',
	widgets: [
		{ content: WIDGETS.WELCOME, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.MEETING, breakpoints: { xs: 12, md: 6 }, lazy_loading: 0 },
		{ content: WIDGETS.COMMUNITY, breakpoints: { xs: 12, md: 6 }, lazy_loading: 0 },
		{ content: WIDGETS.LIBRARY, breakpoints: { xs: 12, md: 6 }, lazy_loading: 0 },
		{ content: WIDGETS.MYFILES, breakpoints: { xs: 12, md: 6 }, lazy_loading: 0 },
	],
};
