import axios from 'axios';
import { FETCH_CONDITIONS_DATA, SELECT_CATEGORY, FETCH_CONDITIONS_NAME_DATA } from './types';
import { store } from '../../../store.js';
import { handleLoadingAction } from '../../Generic/actions/genericActions';
import { push } from 'connected-react-router';
import { apiRequest } from '../../../helpers/functions/request';

export const fetchConditionsAction = (filter = {}, callback = false) => async (dispatch) => {
    const state = store.getState();

    filter.active = true;
    filter.language = state.users.whoami.language;
    filter.client_id = state.login.client.id;

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/conditions/categories`,
        params: filter,
	});

    await dispatch({
        type: FETCH_CONDITIONS_DATA,
        payload: {
            data: response.data,
            filter: filter
        }
    });

    if (callback) {
        return callback();
    }

    return;
};

export const fetchConditionsNameAction = () => dispatch => {
    const state = store.getState();

    axios
        .get(state.config.apihost + `/conditions/categories`, {
            params: {
                active: true,
                language: state.users.whoami.language,
                client_id: state.login.client.id
            }
        })
        .then(res => {
            let conditions = res.data.map(category => category.conditions.map(condition => condition.name));

            dispatch({
                type: FETCH_CONDITIONS_NAME_DATA,
                payload: conditions.flat()
            });
        })
        .catch(err => {
            console.log(err);
            console.log('Error fetching data.');
        });
};

export const getConditionAction = (id, callback = false) => dispatch => {
    dispatch(handleLoadingAction(true));

    const state = store.getState();

    axios
        .get(state.config.apihost + `/conditions/` + id)
        .then(res => {
            dispatch({
                type: SELECT_CATEGORY,
                payload: res.data
            });
            
            if(callback) {
                callback();           
            }
        })
        .catch(err => {
            console.log(err);
            console.log('Error fetching data.');
            dispatch(handleLoadingAction(false));
        });
};
