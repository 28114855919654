import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { getTranslate } from 'react-localize-redux';
import { formValueSelector } from 'redux-form';

import { isIOS } from 'react-device-detect';
import { isMobileSize } from '../../helpers/functions/functions';
import { AppBar, Tabs, Tab, Paper, Box, Typography } from '@mui/material';

import { Alert } from '@mui/material';

import LoginForm from '../Login/LoginForm';
import LoginBankID from '../Login/LoginBankID';
import LoginFrejaID from '../Login/LoginFrejaID';
import LoginSITHS from '../Login/LoginSITHS';
import BankIDlogo from '../../assets/images/bankid.png';
import FrejaIDlogo from '../../assets/images/frejaid.png';
import NetIDlogo from '../../assets/images/netid.png';

import TabContainer from '../Generic/TabContainer';

import { getMeetingAccessLoginAction, bankIDQRGeneratorMeetingAction } from '../Meetings/actions/meetingsActions';

class MeetingLoginTabs extends Component {
	constructor(props) {
		super(props);

		this.loginMethods = this.props.settings.supported_login_methods
			? this.props.settings.supported_login_methods
					.replace(/,\s*$/, '')
					.split(',')
					.map((item) => item.trim())
					.filter((item) => item.length > 0)
			: [];

		this.state = {
			selected:
				typeof this.props.settings.preferred_login_method !== 'undefined'
					? this.props.settings.preferred_login_method
					: this.loginMethods.length > 0
					? this.loginMethods[0]
					: 'password',
		};
	}

	handleChange = (event, newValue) => {
		if (newValue === 'bankid' && this.props.client !== null && this.props.client.id != '0' && !isMobileSize()) {
			this.props.bankIDQRGeneratorMeetingAction(this.props.selectedLanguage);
		}

		this.setState({
			selected: newValue,
		});
	};

	getMeetingLogin = (form) => {
		this.props.getMeetingAccessLoginAction(this.props.formUserInfo, this.props.meeting.token, form);
	};

	render() {
		const { translate } = this.props;
		const { theme } = this.props;

		return (
			<Paper elevation={this.props.client !== null && this.props.client.id == '0' ? 3 : 0} className="meeting-login-content-right-content-tabs">
				{this.loginMethods.length > 0 ? (
					this.loginMethods.length > 1 ? (
						<Tabs
							sx={{ borderBottom: 1, borderColor: 'divider' }}
							value={this.state.selected}
							onChange={this.handleChange}
							indicatorColor="secondary"
							textColor="primary"
							variant="fullWidth"
							scrollButtons="auto"
							centered
						>
							{this.loginMethods
								.filter((item) => item !== 'bankid_qrcode')
								.map((method, key) => (
									<Tab key={key} label={translate('login_tab_' + method)} value={method} />
								))}
						</Tabs>
					) : null
				) : null}
				{this.loginMethods.includes('password') ? (
					<TabContainer
						parent="meeting_logins"
						value={this.state.selected}
						index="password"
						dir={theme.direction}
						style={{ paddingTop: '30px' }}
					>
						<LoginForm
							disableSelfRegistration={true}
							type="login_meeting"
							selectedLanguage={this.props.selectedLanguage}
							handleSubmitAction={this.getMeetingLogin}
						/>
					</TabContainer>
				) : (
					false
				)}
				{this.loginMethods.includes('bankid') ? (
					<TabContainer
						parent="meeting_logins"
						value={this.state.selected}
						index="bankid"
						dir={theme.direction}
						style={{ paddingTop: '30px' }}
					>
						{isIOS ? (
							<Alert className="login-bankid-alert-infobox" severity="info">
								{' '}
								{translate('login_bankid_alert_infobox')}{' '}
							</Alert>
						) : null}
						<img src={BankIDlogo} height="70" alt="BankID logo" />

						<LoginBankID selectedLanguage={this.props.selectedLanguage} handleSubmitAction={this.getMeetingLogin} type="login_meeting" />
					</TabContainer>
				) : (
					false
				)}
				{this.loginMethods.includes('frejaid') ? (
					<TabContainer value={this.state.selected} index="frejaid" dir={theme.direction} style={{ paddingTop: '30px' }}>
						{isIOS ? (
							<Alert className="login-frejaid-alert-infobox" severity="info">
								{' '}
								{translate('login_frejaid_alert_infobox')}{' '}
							</Alert>
						) : null}
						<Box mb={2}>
							<Typography align="center" variant="body2" component="p" gutterBottom>
								{translate('user_register_frejaid_info')}
							</Typography>
						</Box>
						<img src={FrejaIDlogo} height="70" alt="FrejaID logo" />
						<LoginFrejaID selectedLanguage={this.props.selectedLanguage} handleSubmitAction={this.getMeetingLogin} type="login_meeting" />
					</TabContainer>
				) : (
					false
				)}
			</Paper>
		);
	}
}

const selector = formValueSelector('meeting_public_user_language');
const mapStateToProps = (state) => ({
	settings: state.settings.site,
	translate: getTranslate(state.localize),
	client: state.login.client,
	meeting: state.meetings,
	selectedLanguage: selector(state, 'language'),
});

export default withStyles(null, { withTheme: true })(
	connect(mapStateToProps, { bankIDQRGeneratorMeetingAction, getMeetingAccessLoginAction })(MeetingLoginTabs)
);
