import React, { Component } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Container, Box, Card, CardContent } from '@mui/material';
import { getTranslate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			location: props.history.location.pathname,
			hasError: false,
			errorMessage: '',
			type: 'generic',
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, errorMessage: error.toString() };
	}

	static getDerivedStateFromProps(props, state) {
		if (state.location !== props.history.location.pathname) {
			return { hasError: false, location: props.history.location.pathname, errorMessage: '' };
		}

		return null;
	}

	render() {
		const { translate } = this.props;

		if (this.state.hasError) {
			return (
				<React.Fragment>
					<Grid container component="main" justifyContent="center" alignContent="center" className={'error-boundary-main-component'}>
						<Grid item xs={12} md={12}>
							<ErrorOutlineIcon color="error" className={'error-boundary-main-icon'} />
							<Typography color={'inherit'} variant="h3">
								{translate('error_boundary_title')}
							</Typography>
							<Typography color={'inherit'} paragraph={true} component="div" variant="body1">
								{translate('error_boundary_description')}
							</Typography>
							<Button
								onClick={() => {
									this.setState({ hasError: false, errorMessage: '' });
								}}
								variant="body2"
								startIcon={<RefreshIcon />}
							>
								{translate('error_boundary_try_again_button')}
							</Button>
							<Button
								onClick={() => {
									this.setState({ hasError: false, errorMessage: '' }, () => {
										this.props.goBack();
									});
								}}
								variant="body2"
								startIcon={<KeyboardReturnIcon />}
							>
								{translate('error_boundary_go_back_button')}
							</Button>
						</Grid>
					</Grid>
					<Box container mt={2} mb={4}>
						<Container maxWidth={'md'}>
							<Card>
								<CardContent>
									<Typography color={'inherit'} variant="body2">
										{this.state.errorMessage}
									</Typography>
								</CardContent>
							</Card>
						</Container>
					</Box>
				</React.Fragment>
			);
		}

		return this.props.children;
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, {
	goBack,
})(withRouter(ErrorBoundary));
