import React from 'react';

const FilesForPatients = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./FilesForPatients'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyPages = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./MyPages'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyProfile = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./MyProfile'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Password = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./ChangePassword/Password'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyTexts = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./MyTexts'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyCalendar = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./Calendar/MyCalendar'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const PrivacyPolicy = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./PrivacyPolicy'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Appointments = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./Appointments'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyDashboard = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./MyDashboard'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});

export { FilesForPatients, MyPages, MyProfile, MyTexts, MyCalendar, PrivacyPolicy, Password, Appointments, MyDashboard };
